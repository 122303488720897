import {useEffect, useState} from "react";
import {useHttpClient} from "../../shared/http-client";
import {toast} from "react-toastify";

export const HomeBody = () => {
    const {api, state} = useHttpClient();

    const [data, setData] = useState([]);
    const [presupuestos, setPresupuestos] = useState([]);

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (state.data) {
            setData(state.data.dias);
            setPresupuestos(state.data.presupuestos);
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <div className={"grid grid-cols-7 gap-2"}>

        </div>
    )
}

