import { TrashIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageBody } from '../../../elements/page-body';
import { Roles } from '../../../layouts/types';
import { useDrawer } from '../../../shared/drawer';
import { useHttpClient } from '../../../shared/http-client';
import { useModal } from '../../../shared/modals';
import { StoreKeys, useStore } from '../../../shared/store';
import { ProjectServiciosForm } from './proyecto-servicios-form';

interface ProyectoServiciosProps {}

export const ProyectoServicios: React.FC<ProyectoServiciosProps> = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { api, state } = useHttpClient();
    const { open: openModal, close: closeModal } = useModal();
    const navigate = useNavigate();
    const { open, close } = useDrawer();
    const [refresh, setRefresh] = useState<string | null>(null);
    const [filters, setFilters] = useState<any>({});
    const [query, setQuery] = useState<any>(new URLSearchParams(filters));
    const [user, setUser] = useState<any | null>(null);
    const [data, setData] = useState<any | null>(null);

    const { get } = useStore();

    useEffect(() => {
        const user = get(StoreKeys.Profile);
        setUser(user);
    }, []);

    useEffect(() => {
        if (id) {
            api(`/proyectos/${id}/`, 'GET');
        }
    }, [id]);

    useEffect(() => {
        if (state.data) {
            if (state.method === 'GET') {
                setData(state.data);
            }
            if (state.method === 'PATCH') {
                api(`/proyectos/${id}/`, 'GET');
            }
        }

        if (state.error) {
            toast.error(state.error.message);
        }
    }, [state]);

    const handleNewAction = () => {
        open(
            'project-servicios.form.new-item',
            <ProjectServiciosForm close={handleClose} item={data} />
        );
    };

    const handleClose = () => {
        api(`/proyectos/${id}/`, 'GET');
        close();
    };

    const handleDelete = (item: any) => {
        openModal('error', {
            title: 'common.modal.delete',
            message: 'common.modal.validate-delete',
            onAccept: () => {
                closeModal();
                const servicios = data.servicios.filter(
                    (u: any) => u.id !== item.id
                );
                const aList = servicios.map((u: any) => u.id);
                api(`/proyectos/${id}/`, 'PATCH', {
                    servicios: aList
                });
            }
        });
    };

    return (
        <PageBody
            newAction={handleNewAction}
            title={t('common.label.project-servicios')}
            newActionTitle={'project-servicios.button.new'}
        >
            {data && data.servicios && data.servicios.length > 0 ? (
                <>
                    {data.servicios.map((item: any) => {
                        return (
                            <div
                                key={item.id}
                                className="text-sm w-full border-b-2 border-b-gray-200 py-1 flex flex-row justify-between items-center space-x-8"
                            >
                                <div className="flex-1 grid grid-cols-2 gap-4">
                                    <div className="flex-1">{item.name}</div>
                                    <div>{item.text}</div>
                                </div>
                                <div>
                                    <button
                                        data-testid={`delete-button`}
                                        className="p-2 rounded-md"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDelete(item);
                                        }}
                                    >
                                        <TrashIcon className="h-5 w-5 " />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </>
            ) : (
                <div className="w-full py-4 bg-gray-50 rounded-md text-center pt-4">
                    <span className="not-italic font-light text-sm leading-6 text-gray-darker">
                        {t('common.message.no-data')}
                    </span>
                </div>
            )}
        </PageBody>
    );
};

