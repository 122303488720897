import { useState } from 'react';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Table } from '../../../../elements/table';
import { useNavigate, useParams } from 'react-router-dom';

export const ArchivosCaidaTableComponent = ({
    refresh
}: {
    refresh: string | null;
}) => {
    dayjs.extend(localeData);
    dayjs.extend(weekday);
    dayjs.extend(isoWeek);

    const { id } = useParams();

    return (
        <div className="flex h-full flex-col">
            <Table
                path="/caida-tension"
                query={`proyecto=${id}`}
                compressed
                refresh={refresh}
                noEdit
                noDelete
                headers={[
                    {
                        key: 'tramo',
                        label: 'common.label.tramo'
                    },
                    {
                        key: 'numero_viviendas',
                        label: 'common.label.numero_viviendas'
                    },
                    {
                        key: 'grado_electricidad',
                        label: 'common.label.grado_electricidad'
                    },
                    {
                        key: 'suma_potencia',
                        label: 'common.label.suma_potencia'
                    },
                    {
                        key: 'coseno',
                        label: 'common.label.coseno'
                    },
                    {
                        key: 'i_a',
                        label: 'common.label.i_a'
                    },
                    {
                        key: 'metros',
                        label: 'common.label.metros'
                    },
                    {
                        key: 'conductor_actual',
                        label: 'common.label.conductor_actual'
                    },
                    {
                        key: 'sat',
                        label: 'common.label.sat'
                    },
                    {
                        key: 'cdt_tram',
                        label: 'common.label.cdt_tram',
                        type: 'decimal'
                    },
                    {
                        key: 'cdt_acum',
                        label: 'common.label.cdt_acum',
                        type: 'decimal'
                    }
                ]}
            />
        </div>
    );
};

