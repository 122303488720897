import {
    GlobeIcon,
    HomeIcon,
    MapIcon,
    OfficeBuildingIcon,
    TemplateIcon,
    UsersIcon
} from '@heroicons/react/solid';
import { MenuNavigationItem, Roles } from '../types';

export const MENU_NAVIGATION_LINKS: MenuNavigationItem[] = [
    // { name: 'Home', href: '/', icon: HomeIcon, current: false },
    // {name: 'Home', href: '/', icon: HomeIcon, current: false,
    //     roles: [Roles.Admin],
    // },
    {
        name: 'Proyectos',
        href: '/proyectos',
        icon: MapIcon,
        current: false,
        roles: [Roles.Admin, Roles.User]
    },
    {
        name: 'Ajustes',
        icon: UsersIcon,
        current: false,
        roles: [Roles.Admin],
        children: [
            {
                name: 'Usuarios',
                href: '/usuarios',
                icon: UsersIcon,
                current: false
            },
            {
                name: 'Organismos afectados',
                href: '/organismos',
                icon: OfficeBuildingIcon,
                current: false
            },
            {
                name: 'Servicios afectados',
                href: '/servicios',
                icon: OfficeBuildingIcon,
                current: false
            },
            {
                name: 'Templates',
                href: '/templates',
                icon: TemplateIcon,
                current: false
            }
        ]
    }
];

