import { PageBody } from '../../elements/page-body';
import { useSearchParams } from 'react-router-dom';
import { useDrawer } from '../../shared/drawer';
import { useEffect, useState } from 'react';
import { ProyectosComponent } from './proyectos.component';
import { ProyectoFilter } from './proyecto.filter';
import { ProyectoForm } from './proyecto.form';
import { useHttpClient } from '../../shared/http-client';
import { getQueryString } from '../../utils';
import { toast } from 'react-toastify';

export const Proyectos = () => {
    const [params] = useSearchParams();
    const { open, close } = useDrawer();
    const { api, state } = useHttpClient();

    const [filters, setFilters] = useState<any>({});
    const [refresh, setRefresh] = useState<string | null>(null);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleFilters = () => {
        open(
            'proyectos.filter.title',
            <ProyectoFilter close={close} doFilter={doFilter} item={filters} />,
            false,
            '2xl'
        );
    };

    const doFilter = (filters: any) => {
        close();
        setFilters(filters);
    };

    const doExporta = () => {
        const endpoint = `/proyectos/exporta_xls/?limit=9999999999&${getQueryString(
            filters
        )}`;
        api(endpoint, 'GET', null, false, true);
    };

    useEffect(() => {
        console.log(state.data);
        if (state.data) {
            if (state.path.includes('proyectos')) {
                const url = window.URL.createObjectURL(new Blob([state.data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', 'listado_proyectos.xlsx');

                document.body.appendChild(link);
                setIsLoading(false);

                link.click();
            }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    const handleNewAction = () => {
        open('proyectos.form.new-item', <ProyectoForm close={handleClose} />);
    };

    const handleClose = () => {
        setRefresh(Date.now().toString());
        close();
    };

    return (
        <PageBody
            title={'proyectos.text.title'}
            newAction={handleNewAction}
            newActionTitle={'proyectos.button.new'}
            filterAction={handleFilters}
            exportAction={doExporta}
            exportStatus={isLoading}
            exportActionTitle="Exportar XLSX"
        >
            <ProyectosComponent
                selected={params.get('selected')}
                filters={filters}
                refresh={refresh}
            />
        </PageBody>
    );
};

