import React from 'react';
import {PageBodyTypes} from './types';
import {PageHeader} from "../page-header/page-header";
import {classNames} from "../../utils";

export const PageBody = ({
                             title = "",
                             infoText,
                             filterAction,
                             filters,
                             newAction,
                             newActionTitle,
                             secondaryAction,
                             secondaryActionTitle,
                             exportAction,
                             exportStatus,
                             exportActionTitle,
                             children,
                             noMargin = false
                         }: PageBodyTypes) => {
    return (
        <main className="flex-1">
            <div className={noMargin ? "py-0" : "py-8"}>
                <PageHeader
                    title={title}
                    filterAction={filterAction}
                    filters={filters}
                    newAction={newAction}
                    newActionTitle={newActionTitle}
                    secondaryAction={secondaryAction}
                    secondaryActionTitle={secondaryActionTitle}
                    exportAction={exportAction}
                    exportStatus={exportStatus}
                    exportActionTitle={exportActionTitle}
                    hasInfo={!!infoText}
                />
                <div className={classNames(
                    noMargin ? "py-0" : "px-4 sm:px-6 md:px-8 mt-[8px]",
                    "mx-auto"
                )}>
                    <div className="py-4">{children}</div>
                </div>
            </div>
        </main>
    );
};
