import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../../../shared/http-client';
import { Input } from '../../../../elements/input';
import { Button } from '../../../../elements/button';
import { TextArea } from '../../../../elements/text-area';
import { ProyectoOrganismos } from '../../components/proyecto-organismos';
import { ProyectoServicios } from '../../components/proyecto-servicios';

// construccion del objeto yup de validacion del cuestionario
let obligado = {
    // plano: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {};

export const PlanosDecodingForm = ({
    item,
    close
}: {
    item?: any;
    close: () => void;
}) => {
    // console.log('USUARIOS FORM :: EDIT ITEM', item);

    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        const enviar = { ...values };
        enviar.codigo_postal = values?.codigo_postal?.id ?? null;
        enviar.template_memoria = values?.template_memoria?.id ?? null;
        enviar.template_instancia = values?.template_instancia?.id ?? null;
        delete enviar.plano;
        delete enviar.organismos;
        delete enviar.servicios;
        delete enviar.owner;
        return enviar;
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        console.log('ENVIO', data.id, enviaremos);

        if (data.id) {
            // update
            api(`/proyectos/${data.id}/`, 'PATCH', enviaremos);
        } else {
            // create
            api('/proyectos/', 'POST', enviaremos);
        }
    };

    const handleIsProcesado = async () => {
        //
        const enviaremos = {
            is_procesado: true
        };
        api(`/proyectos/${data.id}/`, 'PATCH', enviaremos);
    };

    useEffect(() => {
        // console.log('STATE DATA', state);

        if (state.data) {
            setData({
                ...data,
                is_procesado: state.data.is_procesado ?? false
            });
            toast.success(`${t('common.success.sent')}`);
            close();
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={data || initialValues}
            validationSchema={validacion}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    {/*<pre>{JSON.stringify(item, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                    <div
                        className={'flex flex-row items-center justify-between'}
                    >
                        <Button
                            block
                            dataTestId={'save-button'}
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            label={t('common.button.save')}
                        />
                        <div className={'w-4'} />
                        <Button
                            dataTestId={'procesado-button'}
                            onClick={handleIsProcesado}
                            disabled={isSubmitting}
                            label={t('common.button.procesado')}
                        />
                    </div>

                    {values.is_procesado ? (
                        <div
                            className={
                                'my-4 py-4 text-center bg-gray-100 rounded-md'
                            }
                        >
                            {t('common.button.procesado')}
                        </div>
                    ) : null}

                    {values.tipo ? (
                        <div
                            className={
                                'text-sm font-semibold my-4 text-primary'
                            }
                        >
                            {t('plano.form.tipo')}: {values.tipo}
                        </div>
                    ) : null}

                    <Input
                        value={values.seccion_cable || ''}
                        label={t('plano.form.seccion_cable')}
                        type="text"
                        name="seccion_cable"
                        placeholder={t('plano.form.seccion_cable')}
                        onChange={(value) =>
                            setFieldValue('seccion_cable', value.seccion_cable)
                        }
                    />

                    <Input
                        value={values.total_cable || ''}
                        label={t('plano.form.total_cable')}
                        type="text"
                        name="total_cable"
                        placeholder={t('plano.form.total_cable')}
                        onChange={(value) =>
                            setFieldValue('total_cable', value.total_cable)
                        }
                    />

                    {values.cable_real ? (
                        <div
                            className={
                                'text-sm font-semibold mb-4 -mt-3 text-primary'
                            }
                        >
                            {t('plano.form.cable_real')}: {values.cable_real}
                        </div>
                    ) : null}

                    <Input
                        value={values.total_rasa || ''}
                        label={t('plano.form.total_rasa')}
                        type="text"
                        name="total_rasa"
                        placeholder={t('plano.form.total_rasa')}
                        onChange={(value) =>
                            setFieldValue('total_rasa', value.total_rasa)
                        }
                    />

                    <TextArea
                        label={t('plano.form.title')}
                        name="title"
                        value={values.title}
                        onChange={(value) =>
                            setFieldValue('title', value.title)
                        }
                    />

                    <Input
                        value={values.servicio || ''}
                        label={t('plano.form.servicio')}
                        type="text"
                        name="servicio"
                        placeholder={t('plano.form.servicio')}
                        onChange={(value) =>
                            setFieldValue('servicio', value.servicio)
                        }
                    />

                    <Input
                        value={values.direccion || ''}
                        label={t('plano.form.direccion')}
                        type="text"
                        name="direccion"
                        placeholder={t('plano.form.direccion')}
                        onChange={(value) =>
                            setFieldValue('direccion', value.direccion)
                        }
                    />

                    <Input
                        value={values.cp || ''}
                        label={t('plano.form.cp')}
                        type="text"
                        name="cp"
                        placeholder={t('plano.form.cp')}
                        onChange={(value) => setFieldValue('cp', value.cp)}
                    />

                    <Input
                        value={values.voltaje || ''}
                        label={t('plano.form.voltaje')}
                        type="text"
                        name="voltaje"
                        placeholder={t('plano.form.voltaje')}
                        onChange={(value) =>
                            setFieldValue('voltaje', value.voltaje)
                        }
                    />

                    <Input
                        value={values.expediente || ''}
                        label={t('plano.form.expediente')}
                        type="text"
                        name="expediente"
                        placeholder={t('plano.form.expediente')}
                        onChange={(value) =>
                            setFieldValue('expediente', value.expediente)
                        }
                    />

                    <Input
                        value={values.potencia || ''}
                        label={t('plano.form.potencia')}
                        type="text"
                        name="potencia"
                        placeholder={t('plano.form.potencia')}
                        onChange={(value) =>
                            setFieldValue('potencia', value.potencia)
                        }
                    />

                    <Input
                        value={values.cliente || ''}
                        label={t('plano.form.cliente')}
                        type="text"
                        name="cliente"
                        placeholder={t('plano.form.cliente')}
                        onChange={(value) =>
                            setFieldValue('cliente', value.cliente)
                        }
                    />

                    <Input
                        value={values.tm || ''}
                        label={t('plano.form.tm')}
                        type="text"
                        name="tm"
                        placeholder={t('plano.form.tm')}
                        onChange={(value) => setFieldValue('tm', value.tm)}
                    />

                    <Input
                        value={values.titulo || ''}
                        label={t('plano.form.titulo')}
                        type="text"
                        name="titulo"
                        placeholder={t('plano.form.titulo')}
                        onChange={(value) =>
                            setFieldValue('titulo', value.titulo)
                        }
                    />

                    <Input
                        value={values.niter || ''}
                        label={t('plano.form.niter')}
                        type="text"
                        name="niter"
                        placeholder={t('plano.form.niter')}
                        onChange={(value) =>
                            setFieldValue('niter', value.niter)
                        }
                    />

                    <Input
                        value={values.ads || ''}
                        label={t('plano.form.ads')}
                        type="text"
                        name="ads"
                        placeholder={t('plano.form.ads')}
                        onChange={(value) => setFieldValue('ads', value.ads)}
                    />

                    <Input
                        value={values.iter || ''}
                        label={t('plano.form.iter')}
                        type="text"
                        name="iter"
                        placeholder={t('plano.form.iter')}
                        onChange={(value) => setFieldValue('iter', value.iter)}
                    />

                    <Input
                        value={values.seccion_2 || ''}
                        label={t('plano.form.seccion_2')}
                        type="text"
                        name="seccion_2"
                        placeholder={t('plano.form.seccion_2')}
                        onChange={(value) =>
                            setFieldValue('seccion_2', value.seccion_2)
                        }
                    />

                    <Input
                        value={values.im3_2 || ''}
                        label={t('plano.form.im3_2')}
                        type="text"
                        name="im3_2"
                        placeholder={t('plano.form.im3_2')}
                        onChange={(value) =>
                            setFieldValue('im3_2', value.im3_2)
                        }
                    />

                    <ProyectoOrganismos />
                    <ProyectoServicios />
                </form>
            )}
        </Formik>
    );
};
