import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useHttpClient} from '../../shared/http-client';
import {StoreKeys, useStore} from '../../shared/store';
import {APP_NAME} from "../../constants";
import LOGO from '../../../assets/images/logo.jpg'

export const Login = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {api, state} = useHttpClient();
    const {set} = useStore();
    const [formState, setFormState] = React.useState({
        email: '',
        password: ''
    });
    useEffect(() => {
        // init()
    }, []);

    useEffect(() => {


        if (state.data) {

            if (state.path.includes('login')) {
                set(StoreKeys.Token, state.data);
                api('/users/me/', 'GET');
            }

            if (state.path.includes('me')) {
                set(StoreKeys.Profile, state.data);
                navigate('/');
                window.location.reload();
            }

        }

        if (state.error) {
            console.log('state', state)
            toast.error(`${t("login.error")}`);
        }
    }, [state]);

    const handleChange = (e: any) => {
        setFormState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleLogin = async () => {
        api('/auth/login/', 'POST', formState);
    };

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                    <div className="space-y-8">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                className="h-16 w-auto"
                                src={LOGO}
                                alt="Workflow"
                            />
                            <div className={"text-xl font-bold"}>{APP_NAME}</div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                {t('login.text.email')}*
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    value={formState.email || ''}
                                    onChange={handleChange}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-dark focus:border-primary-dark sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                {' '}
                                {t('login.text.password')}*{' '}
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    value={formState.password || ''}
                                    onChange={handleChange}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-dark focus:border-primary-dark sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            {/*<div className="flex items-center">*/}
                            {/*    <input*/}
                            {/*        id="remember-me"*/}
                            {/*        name="remember-me"*/}
                            {/*        type="checkbox"*/}
                            {/*        className="h-4 w-4 text-primary focus:ring-primary-dark border-gray-300 rounded"*/}
                            {/*    />*/}
                            {/*    <label className="ml-2 block text-sm text-gray-900">*/}
                            {/*        {' '}*/}
                            {/*        {t('login.text.remember')}{' '}*/}
                            {/*    </label>*/}
                            {/*</div>*/}

                            <div className="text-sm">
                                <Link to="/forgot">
                                    <div className="font-medium text-primary hover:text-primary-dark">
                                        {t('login.text.forgot.password')}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                onClick={handleLogin}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark"
                            >
                                {t('login.button.login')}
                            </button>
                        </div>
                    </div>

                    {/*<div className="mt-6">*/}
                    {/*    <div className="relative">*/}
                    {/*        <div className="absolute inset-0 flex items-center">*/}
                    {/*            <div className="w-full border-t border-gray-300"></div>*/}
                    {/*        </div>*/}
                    {/*        <div className="relative flex justify-center text-sm">*/}
                    {/*            <span className="px-2 bg-white text-gray-500">*/}
                    {/*                {t('login.text.no-acount')}*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="mt-6">*/}
                    {/*        <div>*/}
                    {/*            <div*/}
                    {/*                onClick={handleSignup}*/}
                    {/*                className="w-full flex justify-center py-2 px-4 border border-primary rounded-md shadow-sm text-sm font-medium text-primary bg-transparent hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark"*/}
                    {/*            >*/}
                    {/*                {t('login.button.signup')}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};
