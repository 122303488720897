import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Input } from '../../elements/input';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../shared/http-client';
import { FormFooter } from '../../elements/form-footer';
import { FieldError } from '../../elements/field-error/field-error';
import { FileInputElement } from '../../elements/file-input';
import { TextArea } from '../../elements/text-area';
import { Select } from '../../elements/select';
import { PLANTILLAS } from './constants';

// construccion del objeto yup de validacion del cuestionario
let obligado = {
    name: Yup.string().required('obligatorio')
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    name: '',
    text: ''
};

export const OrganismosForm = ({
    item,
    close,
    doDelete
}: {
    item?: any;
    close: () => void;
    doDelete?: (item: any) => void;
}) => {
    // console.log('USUARIOS FORM :: EDIT ITEM', item);

    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        const after = { ...values };
        after.plantilla = PLANTILLAS.find(
            (item) => item.id === values.plantilla
        )?.id;

        return { ...after };
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        let formdata = new FormData();
        if (enviaremos.archivo) {
            if (enviaremos.archivo.archivo) {
                formdata.append(
                    'archivo',
                    enviaremos.archivo.archivo[0],
                    enviaremos.archivo.archivo[0].name
                );
            }
        }

        for (let key in enviaremos) {
            if (key !== 'archivo') {
                formdata.append(key, enviaremos[key] ? enviaremos[key] : '');
            }
        }

        if (enviaremos.id) {
            // update
            api(
                `/organismos-afectados/${enviaremos.id}/`,
                'PATCH',
                formdata,
                true
            );
        } else {
            // create
            api('/organismos-afectados/', 'POST', formdata, true);
        }
    };

    useEffect(() => {
        // console.log('STATE DATA', state);

        if (state.data) {
            close();
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={data || initialValues}
            validationSchema={validacion}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    {/*<pre>{JSON.stringify(item, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                    <Input
                        label={t('common.form-label.name')}
                        type="text"
                        placeholder={t('common.form-label.name')}
                        name="name"
                        value={values.name}
                        onChange={(value) => setFieldValue('name', value.name)}
                    />
                    <FieldError
                        touched={touched}
                        errors={errors}
                        field={'name'}
                    />

                    <Input
                        label={t('common.form-label.text')}
                        type="text"
                        placeholder={t('common.form-label.text')}
                        name="text"
                        value={values.text}
                        onChange={(value) => setFieldValue('text', value.text)}
                    />
                    <FormFooter
                        item={item}
                        doDelete={doDelete}
                        doSubmit={handleSubmit}
                        close={close}
                    />
                </form>
            )}
        </Formik>
    );
};

