import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Input } from '../../elements/input';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../shared/http-client';
import { FormFooter } from '../../elements/form-footer';
import { FieldError } from '../../elements/field-error/field-error';

// construccion del objecto yup de validacion del cuestionario
let obligado = {
    name: Yup.string().required('obligatorio')
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    name: ''
};

export const ProyectoForm = ({
    item,
    close,
    doDelete
}: {
    item?: any;
    close: () => void;
    doDelete?: (item: any) => void;
}) => {
    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any) => {
        const temp = { ...values };
        temp.codigo_postal = values?.codigo_postal?.id ?? null;
        temp.template_memoria = values?.template_memoria?.id ?? null;
        temp.template_instancia = values?.template_instancia?.id ?? null;
        return { ...temp };
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);
        if (enviaremos.id) {
            // update
            api(`/proyectos/${enviaremos.id}/`, 'PATCH', enviaremos);
        } else {
            // create
            api('/proyectos/', 'POST', enviaremos);
        }
    };

    useEffect(() => {
        if (state.data) {
            if (state.path.includes('proyectos')) {
                close();
            }
        }

        if (state.error) {
            console.log('state.error', state.error);
            if ('name' in state.error) {
                toast.error(t(state.error.name[0]) as any);
            } else {
                toast.error(state.error.detail.detail);
            }
        }
    }, [state]);

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={data || initialValues}
                validationSchema={validacion}
                validateOnBlur={true}
                onSubmit={onSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        {/*<pre>{JSON.stringify(item, null, 4)}</pre>*/}
                        {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                        <Input
                            value={values.name}
                            label={t('proyecto.form.name')}
                            type="text"
                            name="name"
                            placeholder={t('proyecto.form.name')}
                            onChange={(value) =>
                                setFieldValue('name', value.name)
                            }
                        />
                        <FieldError
                            touched={touched}
                            errors={errors}
                            field={'name'}
                        />

                        <FormFooter
                            item={item}
                            doDelete={doDelete}
                            doSubmit={handleSubmit}
                            close={close}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
};

