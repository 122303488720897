import { useState } from 'react';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Table } from '../../../../elements/table';
import { useNavigate, useParams } from 'react-router-dom';
import { getQueryString } from '../../../../utils';
// import {ProyectoForm} from "./proyecto.form";

export const ArchivosResiduosOtrosTableComponent = ({
    refresh
}: {
    refresh: string | null;
}) => {
    dayjs.extend(localeData);
    dayjs.extend(weekday);
    dayjs.extend(isoWeek);

    const { id } = useParams();

    const navigate = useNavigate();

    return (
        <div className="flex h-full flex-col">
            <Table
                path="/residuos-otros"
                query={`proyecto=${id}`}
                compressed
                refresh={refresh}
                noEdit
                noDelete
                headers={[
                    {
                        key: 'name',
                        label: 'common.label.name'
                    },
                    {
                        key: 'cantidad',
                        label: 'common.label.cantidad'
                    },
                    {
                        key: 'unidad',
                        label: 'common.label.unidad'
                    },
                    // {
                    //     key: 'precio_unitario',
                    //     label: 'common.label.precio_unitario'
                    // },
                    {
                        key: 'procedencia',
                        label: 'common.label.procedencia'
                    }
                ]}
            />
        </div>
    );
};
