import { useState } from 'react';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Table } from '../../elements/table';
import { useNavigate } from 'react-router-dom';
import { getQueryString } from '../../utils';
import { ProyectoForm } from './proyecto.form';

export const ProyectosComponent = ({
    selected,
    filters,
    refresh
}: {
    selected: string | null | undefined;
    filters: any;
    refresh: string | null;
}) => {
    dayjs.extend(localeData);
    dayjs.extend(weekday);
    dayjs.extend(isoWeek);

    const navigate = useNavigate();

    const handleItemClick = (item: any) => {
        navigate(`/proyectos/${item.id}/planos`);
    };

    return (
        <div className="flex h-full flex-col">
            <Table
                path="/proyectos"
                deletePath="/proyectos"
                query={getQueryString(filters)}
                Form={ProyectoForm}
                compressed
                refresh={refresh}
                onShow={handleItemClick}
                withPagination
                headers={[
                    {
                        key: 'name',
                        label: 'common.label.name'
                    },
                    {
                        key: 'expediente',
                        label: 'common.label.expediente'
                    },
                    {
                        key: 'im3_2',
                        label: 'common.label.im3_2'
                    },
                    {
                        key: 'creado',
                        label: 'common.label.creado',
                        type: 'date'
                    },
                    {
                        key: 'modificado',
                        label: 'common.label.modificado',
                        type: 'date'
                    },
                    {
                        key: 'owner',
                        label: 'common.label.owner'
                    },
                    {
                        key: 'is_procesado',
                        label: 'common.label.is_procesado',
                        type: 'boolean'
                    }
                ]}
            />
        </div>
    );
};

