import {useEffect, useState} from 'react';

import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {CogIcon, DocumentDuplicateIcon, EyeIcon, PencilIcon, TrashIcon} from '@heroicons/react/outline';

import {classNames, noop} from '../../utils';
import {Button} from '../button';
import {useDrawer} from '../../shared/drawer';
import {AddTree} from '../../../assets/icons/AddTree';
import {useHttpClient} from '../../shared/http-client';
import {useModal} from '../../shared/modals';
import {PaginationComponent} from '../../shared/pagination';
import {renderTableItem} from '../table-item/table-item.element';
import {useStore} from "../../shared/store";

export const Table = ({
                          Form,
                          path,
                          storePath,
                          deletePath = noop(),
                          query = '',
                          onShow,
                          isDuplicate = false,
                          headers,
                          parent = {},
                          buttonLabel,
                          setCount = noop,
                          parentData,
                          isParentData,
                          limit = 20,
                          noAction = false,
                          noDelete = false,
                          noEdit = false,
                          withPagination = false,
                          withTotals = false,
                          callBeforeDrawerClosed = noop,
                          compressed = false,
                          refresh,
                          size = 'xl',
                          doEdit = noop(),
                          doDelete = noop()
                      }: any) => {
    const {t} = useTranslation();
    const {api, state} = useHttpClient();
    const [hover, setHover] = useState<number | null>(null);
    const [data, setData] = useState<any[]>([]);
    const {open: openModal, close: closeModal} = useModal();
    const {open: openDrawer, close: closeDrawer} = useDrawer();
    const {get, set} = useStore();

    const [page, setPage] = useState<number | null>(null);
    const [maxRecords, setMaxRecords] = useState<number>(0);
    const [, setRefreshPagination] = useState<string | null>(null);

    const request = () => {
        // console.log("TABLA :: REQUEST: ", query?.toString())

        if (path && page !== null) {
            let endpoint = `${path}/?${query}${
                withPagination
                    ? `${query ? '&' : ''}limit=${limit}&offset=${page}`
                    : `${query ? '&' : ''}limit=9999999999`
            }`;

            Object.keys(parent).forEach((key: string) => {
                const regex = new RegExp(`:${key}`, 'g');
                endpoint = endpoint.replace(regex, parent[key]);
            });

            // console.log("TABLA :: ENDPOINT: ", endpoint)

            api(endpoint, 'GET');
        }
    };

    const handleClose = () => {
        request();
        callBeforeDrawerClosed();

        setTimeout(() => {
            closeDrawer();
        }, 200);
    };

    const handleEditAction = (item: any) => {
        set(`${storePath}-limit`, limit);
        set(`${storePath}-offset`, page);

        if (doEdit) {
            doEdit(item);
        } else {
            openDrawer(
                'common.form-title.edit-item',
                <Form item={item} close={handleClose}/>,
                true,
                size
            );
        }
    };

    const handleNewOperationAction = () => {
        if (Form) {
            openDrawer(
                'common.form-title.new-item',
                <Form close={handleClose}/>,
                true,
                size
            );
        }
    };

    const handleDelete = (item: any) => {
        if (doDelete) {
            doDelete(item);
        } else {
            openModal('error', {
                title: 'common.modal.delete',
                message: 'common.modal.validate-delete',
                onAccept: () => {
                    closeModal();
                    api(
                        `${deletePath ? deletePath : path}/${item.id}/`,
                        'DELETE'
                    );
                    setData([
                        ...data.filter(
                            (element: { id: number }) => element.id !== item.id
                        )
                    ]);

                    setCount(data.length - 1);
                    setPage(0);
                    setRefreshPagination(Date.now().toString());
                    setTimeout(() => {
                        closeModal();
                        setRefreshPagination(null);
                        callBeforeDrawerClosed();
                    }, 500);
                }
            });
        }
    };

    useEffect(() => {
        if (parentData && Object.keys(parentData).length > 0) {
            setData([...parentData]);
        }
    }, [parentData]);

    useEffect(() => {
        if (storePath) {
            const initialPage = get(`${storePath}-offset`) || 0
            setPage(initialPage)
        } else {
            setPage(0)
        }
    }, []);

    useEffect(() => {
        if (!isParentData && query !== null) {
            if (page !== null) {
                set(`${storePath}-offset`, page);
            }
            request();
        } else if (parentData) {
            setData([...parentData]);
        }
    }, [page, query, refresh]);

    useEffect(() => {
        if (state.data && state.data.results) {
            setData(state.data.results);
            setCount(state.data.results.length);
            setMaxRecords(state.data.count);
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);


    return (
        <div className="flex flex-col mb-3 w-full">
            <div className="overflow-x-auto">
                <div className={'relative'}>
                    {state.isLoading ? (
                        <div className={'absolute inset-0'}>
                            <div className={'w-full flex justify-center my-4'}>
                                <CogIcon className="animate-spin h-5 w-5 text-black"/>
                            </div>
                        </div>
                    ) : null}

                    <table
                        className={classNames(
                            state.isLoading ? 'opacity-30' : '',
                            'w-full max-w-full'
                        )}
                    >
                        <thead className="bg-gray-200
                        ">
                        <tr>
                            {headers.map((header: any, index: number) => (
                                <th
                                    key={index}
                                    scope="col"
                                    style={{
                                        width: header.width || 'auto'
                                    }}
                                    className="py-2 pl-4 pr-3 text-left bg-transparent text-gray-darker sm:pl-6 not-italic font-light text-xs leading-6"
                                >
                                    {t(header.label)}
                                </th>
                            ))}

                            {!noAction
                                ? (
                                    <th
                                        scope="col"
                                        className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        {' '}
                                    </th>
                                ) : null}
                        </tr>
                        </thead>
                        {data.length ? (
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {/*render totals*/}
                            {withTotals
                                ? <tr>
                                    {headers.map(
                                        (header: any, index: number) => (
                                            <td
                                                key={index}
                                                className={
                                                    'py-3.5 pl-4 pr-3 text-left font-bold text-xl'
                                                }
                                            >
                                                {header.totalTitle
                                                    ? t(header['totalTitle'])
                                                    : header.total
                                                        ? header['type'] ===
                                                        'decimal'
                                                            ? (
                                                                header.totalValue ||
                                                                data.reduce(
                                                                    (
                                                                        acc: number,
                                                                        item: any
                                                                    ) =>
                                                                        acc +
                                                                        item[
                                                                            header
                                                                                .key
                                                                            ],
                                                                    0
                                                                )
                                                            ).toFixed(2) +
                                                            ' ' +
                                                            header['totalSuffix']
                                                            : header['type'] ===
                                                            'currency'
                                                                ? (
                                                                    header.totalValue ||
                                                                    data.reduce(
                                                                        (
                                                                            acc: number,
                                                                            item: any
                                                                        ) =>
                                                                            acc +
                                                                            item[
                                                                                header
                                                                                    .key
                                                                                ],
                                                                        0
                                                                    )
                                                                )
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /\d(?=(\d{3})+\.)/g,
                                                                        '$&,'
                                                                    ) +
                                                                ` ${
                                                                    (header as any)
                                                                        ?.totalSuffix ||
                                                                    '€'
                                                                }`
                                                                : (header.totalValue ||
                                                                    data.reduce(
                                                                        (
                                                                            acc: number,
                                                                            item: any
                                                                        ) =>
                                                                            acc +
                                                                            item[
                                                                                header
                                                                                    .key
                                                                                ],
                                                                        0
                                                                    )) +
                                                                ' ' +
                                                                header['totalSuffix']
                                                        : ''}
                                            </td>
                                        )
                                    )}
                                </tr>
                                : null}
                            {/*render totals*/}

                            {data.map((item, index) => (
                                <tr
                                    key={index}
                                    data-testid={`table-row-${index}`}
                                    onMouseEnter={() => setHover(index)}
                                    onMouseLeave={() => setHover(null)}
                                    className="hover:bg-gray-lighterest"
                                >
                                    {renderTableItem(
                                        item,
                                        headers,
                                        'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                                    )}

                                    {!noAction && (
                                        <td
                                            className={classNames(
                                                compressed
                                                    ? 'py-1'
                                                    : 'py-4',
                                                'relative whitespace-nowrap pl-3 pr-4 text-right text-sm font-medium sm:pr-6 z-10'
                                            )}
                                        >
                                            {
                                                <>
                                                    {onShow && (
                                                        <button
                                                            className="p-2 rounded-md"
                                                            data-testid={`eye-button`}
                                                            onClick={
                                                                hover ===
                                                                index
                                                                    ? (
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        onShow(
                                                                            item
                                                                        );
                                                                    }
                                                                    : noop
                                                            }
                                                        >
                                                            {isDuplicate ? (
                                                                <DocumentDuplicateIcon
                                                                    className={classNames(
                                                                        'h-5 w-5 ',
                                                                        hover ==
                                                                        index
                                                                            ? 'text-gray-dark hover:text-gray-darker'
                                                                            : 'text-transparent'
                                                                    )}
                                                                />
                                                            ) : (
                                                                <EyeIcon
                                                                    className={classNames(
                                                                        'h-5 w-5 ',
                                                                        hover ==
                                                                        index
                                                                            ? 'text-gray-dark hover:text-gray-darker'
                                                                            : 'text-transparent'
                                                                    )}
                                                                />
                                                            )}
                                                        </button>
                                                    )}
                                                    {!noEdit ? (
                                                        <button
                                                            className="p-2 rounded-md"
                                                            data-testid={`edit-button`}
                                                            onClick={
                                                                hover ===
                                                                index
                                                                    ? (
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        handleEditAction(
                                                                            item
                                                                        );
                                                                    }
                                                                    : noop
                                                            }
                                                        >
                                                            <PencilIcon
                                                                className={classNames(
                                                                    'h-5 w-5 ',
                                                                    hover ==
                                                                    index
                                                                        ? 'text-gray-dark hover:text-gray-darker'
                                                                        : 'text-transparent'
                                                                )}
                                                            />
                                                        </button>
                                                    ) : null}
                                                    {!noDelete ? (
                                                        <button
                                                            data-testid={`delete-button`}
                                                            className="p-2 rounded-md"
                                                            onClick={
                                                                hover ===
                                                                index
                                                                    ? (
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault()
                                                                        handleDelete(
                                                                            item
                                                                        );
                                                                    }
                                                                    : noop
                                                            }
                                                        >
                                                            <TrashIcon
                                                                className={classNames(
                                                                    'h-5 w-5 ',
                                                                    hover ==
                                                                    index
                                                                        ? 'text-gray-dark hover:text-gray-darker'
                                                                        : 'text-transparent'
                                                                )}
                                                            />
                                                        </button>
                                                    ) : null}
                                                </>
                                            }
                                        </td>
                                    )}
                                </tr>
                            ))}
                            </tbody>
                        ) : (
                            <tbody className="">
                            <tr>
                                <td
                                    colSpan={headers.length + 1}
                                    className="text-center pt-4"
                                >
                                        <span className="not-italic font-light text-sm leading-6 text-gray-darker">
                                            {t('common.message.no-data')}
                                        </span>
                                </td>
                            </tr>
                            </tbody>
                        )}
                    </table>
                </div>

                {withPagination && (
                    <PaginationComponent
                        doPage={(page: number) => {
                            setPage(page);
                        }}
                        limit={limit}
                        maxRecords={maxRecords}
                        offset={page}
                    />
                )}

                {/*        </>*/}
                {/*    )*/}
                {/*}*/}

                {buttonLabel && (
                    <div className="mt-[8px]">
                        <Button
                            variant="gray-light"
                            icon={<AddTree/>}
                            disabled={!parent?.id}
                            label={t(buttonLabel)}
                            onClick={handleNewOperationAction}
                        />
                    </div>
                )}
                {/*</div>*/}
            </div>
        </div>
    );
};

