import { CogIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from '../../../../elements/button';
import { Select } from '../../../../elements/select';
import { useHttpClient } from '../../../../shared/http-client';
import { TIPOS } from '../../constants';

interface IProps {
    data: any;
    reload: () => void;
}

export const BaseTemplateElection: React.FC<IProps> = (props) => {
    const { data, reload } = props;
    const { t } = useTranslation();
    const { api, state } = useHttpClient();

    const [memoria, setMemoria] = useState<{ plantilla: string } | null>(null);
    const [instancia, setInstancia] = useState<{ instancia: string } | null>(
        null
    );
    const [memorias, setMemorias] = useState([]);
    const [instancias, setInstancias] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {
    //     api(`/templates/?plantilla=1`, 'GET');
    // }, []);

    useEffect(() => {
        api(`/templates/?plantilla=1`, 'GET');
    }, [data]);

    const handleSubmitMemoria = () => {
        setIsLoading(true);
        api(
            `/proyectos/${data?.id}/procesa_word_memoria/`,
            'GET',
            null,
            false,
            true
        );
    };

    const handleSubmitMemoriaPdf = () => {
        setIsLoading(true);
        api(
            `/proyectos/${data?.id}/procesa_pdf_memoria/`,
            'GET',
            null,
            false,
            true
        );
    };

    const handleSubmitTemplateMemoria = () => {
        api(`/proyectos/${data.id}/`, 'PATCH', {
            template_memoria: memoria?.plantilla ?? null
        });
    };

    const changeMemoria = (value: any) => {
        setMemoria(value ?? null);
    };

    const handleSubmitInstancia = () => {
        setIsLoading(true);
        api(
            `/proyectos/${data?.id}/procesa_word_instancia/`,
            'GET',
            null,
            false,
            true
        );
    };

    const handleSubmitInstanciaPdf = () => {
        setIsLoading(true);
        api(
            `/proyectos/${data?.id}/procesa_pdf_instancia/`,
            'GET',
            null,
            false,
            true
        );
    };

    const handleSubmitTemplateInstancia = () => {
        api(`/proyectos/${data.id}/`, 'PATCH', {
            template_instancia: instancia?.instancia ?? null
        });
    };

    const handleSubmitPlantillas = () => {
        handleSubmitMemoria();
        setTimeout(() => {
            handleSubmitInstancia();
        }, 500);
        setTimeout(() => {
            handleSubmitMemoriaPdf();
        }, 500);
        setTimeout(() => {
            handleSubmitInstanciaPdf();
        }, 500);
    };

    const changeInstancia = (value: any) => {
        console.log('handleSubmit', value);
        setInstancia(value ?? null);
    };
    useEffect(() => {
        if (!state.isLoading) {
            // if (state.data && state.data.results) {
            // } else
            if (state.data) {
                if (state.path.includes('proyectos')) {
                    if (state.path.includes('/procesa_word_memoria')) {
                        const url = window.URL.createObjectURL(
                            new Blob([state.data])
                        );
                        const link = document.createElement('a');

                        link.href = url;
                        link.setAttribute(
                            'download',
                            `PR_LSBT_${data.im3_2}_${data.expediente}.docx`
                        );

                        document.body.appendChild(link);
                        setIsLoading(false);

                        link.click();
                    } else if (state.path.includes('/procesa_pdf_memoria')) {
                        const url = window.URL.createObjectURL(
                            new Blob([state.data])
                        );
                        const link = document.createElement('a');

                        link.href = url;
                        link.setAttribute(
                            'download',
                            `PR_LSBT_${data.im3_2}_${data.expediente}.pdf`
                        );

                        document.body.appendChild(link);
                        setIsLoading(false);

                        link.click();
                    } else if (state.path.includes('/procesa_word_instancia')) {
                        const url = window.URL.createObjectURL(
                            new Blob([state.data])
                        );
                        const link = document.createElement('a');

                        link.href = url;
                        link.setAttribute(
                            'download',
                            `EXP${data.expediente}_Instància_Ajuntament.docx`
                        );

                        document.body.appendChild(link);
                        setIsLoading(false);

                        link.click();
                    } else if (state.path.includes('/procesa_pdf_instancia')) {
                        const url = window.URL.createObjectURL(
                            new Blob([state.data])
                        );
                        const link = document.createElement('a');

                        link.href = url;
                        link.setAttribute(
                            'download',
                            `EXP${data.expediente}_Instància_Ajuntament.pdf`
                        );

                        document.body.appendChild(link);
                        setIsLoading(false);

                        link.click();
                    } else {
                        toast.success(`${t('common.success.sent')}`);
                        reload();
                    }
                }

                if (state.path.includes('templates/?plantilla=1')) {
                    console.log('memorias', state.data);
                    setMemorias(state.data.results);
                    api(`/templates/?plantilla=2`, 'GET');
                }
                if (state.path.includes('templates/?plantilla=2')) {
                    setInstancias(state.data.results);
                }
            }

            if (state.error) {
                if (state.path.includes('send_pdf_intervencion')) {
                    toast.error(`${t('intervention-detail.error.save-pdf')}`);
                } else {
                    toast.error(state.error.detail);
                }
                setIsLoading(false);
            }
        }
    }, [state]);

    return (
        <>
            <div
                className={'flex justify-between items-start space-x-8 text-sm'}
            >
                <div>
                    <div className={'font-bold'}>{data?.title ?? '-'}</div>
                    <div className={''}>{data?.cliente ?? '-'}</div>
                    <div className={''}>{data?.tm ?? '-'}</div>
                    <div className={''}>{data?.im3_1 ?? '-'}</div>
                </div>
                <div></div>
                <div>
                    <div>{data?.company ?? '-'}</div>
                </div>
            </div>

            <hr className={'my-4'} />

            {isLoading ? (
                <div className={'w-full flex justify-center my-4'}>
                    <CogIcon className="animate-spin h-5 w-5 text-gray-400" />
                </div>
            ) : null}

            <div
                className={
                    'w-full flex justify-between items-start space-x-8 text-sm'
                }
            >
                <div className="w-full flex flex-col space-y-4">
                    <div className="w-full flex flex-row space-x-2 justify-between items-center">
                        <Select
                            name="plantilla"
                            label={t('common.form-label.plantilla')}
                            placeholder={t('common.form-label.plantilla')}
                            items={memorias}
                            value={data?.template_memoria || ''}
                            onChange={(value) => changeMemoria(value)}
                        />
                        <Button
                            label={t('project.label.update-memoria')}
                            onClick={handleSubmitTemplateMemoria}
                        />
                    </div>
                    <Button
                        label={t('project.label.download-memoria')}
                        onClick={handleSubmitMemoria}
                    />
                    <Button
                        label={t('project.label.download-memoria-pdf')}
                        onClick={handleSubmitMemoriaPdf}
                    />
                </div>
                <div className="w-full flex flex-col space-y-4">
                    <div className="w-full flex flex-row space-x-2 justify-between items-center">
                        <Select
                            name="instancia"
                            label={t('common.form-label.instancia')}
                            placeholder={t('common.form-label.instancia')}
                            items={instancias}
                            value={data?.template_instancia || ''}
                            onChange={(value) => changeInstancia(value)}
                        />
                        <Button
                            label={t('project.label.update-instancia')}
                            onClick={handleSubmitTemplateInstancia}
                        />
                    </div>
                    <Button
                        label={t('project.label.download-instancia')}
                        onClick={handleSubmitInstancia}
                    />
                    <Button
                        label={t('project.label.download-instancia-pdf')}
                        onClick={handleSubmitInstanciaPdf}
                    />
                </div>
            </div>

            <hr className={'my-4'} />

            <div className="w-full flex justify-center">
                <Button
                    label={t('project.label.download-plantillas')}
                    onClick={handleSubmitPlantillas}
                />
            </div>
        </>
    );
};

