import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Input } from '../../elements/input';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../shared/http-client';
import { FilterFooter } from '../../elements/filter-footer';

// construccion del objeto yup de validacion del cuestionario
let obligado = {};
const validacion = Yup.object().shape(obligado);

const initialValues = {};

export const CodigosPostalesFilter = ({
    item,
    doFilter,
    close
}: {
    item?: any;
    close: () => void;
    doFilter: (item: any) => void;
}) => {
    const { t } = useTranslation();
    const { state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        let after = { ...values };

        for (let key in after) {
            if (!after[key]) {
                delete after[key];
            }
        }

        return after;
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        doFilter(enviaremos);
    };

    useEffect(() => {
        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={data || initialValues}
                validationSchema={validacion}
                validateOnBlur={true}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue, handleSubmit }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                        {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                        <Input
                            name="code"
                            label={t('common.form-label.code')}
                            type="text"
                            placeholder={t('common.form-label.code')}
                            value={values.code}
                            onChange={(value) =>
                                setFieldValue('code', value.code)
                            }
                        />

                        <Input
                            name="name"
                            label={t('common.form-label.name')}
                            type="text"
                            placeholder={t('common.form-label.name')}
                            value={values.name}
                            onChange={(value) =>
                                setFieldValue('name', value.name)
                            }
                        />

                        <FilterFooter
                            item={item}
                            doDelete={() => doFilter({})}
                            doSubmit={handleSubmit}
                            close={close}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
};

