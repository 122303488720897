import {useEffect, useState} from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {StoreKeys, useStore} from '../shared/store';
import {Modal} from '../shared/modals';

import {LoginContainer} from '../layouts';
import {Forgot, Login, Update} from '../pages/auth';
import {ForgotSuccess} from '../pages/auth/forgot.success';
import {UpdateSuccess} from '../pages/auth/update.success';
import {NotFound} from "../pages/not-found/not-found";
import {MainRouter} from "../pages/main/main.router";


export const BootstrapRouter = () => {
    const {get} = useStore();
    const [logged, setLogged] = useState<boolean>(false);

    useEffect(() => {
        if (get(StoreKeys.Token)) {
            setLogged(true);

            // const profile = get(StoreKeys.Profile);

        }
    }, []);

    useEffect(() => {
        // console.log('logged', logged);
    }, [logged]);

    return (
        <Modal>
            <Router>
                {logged
                    ? (
                        <Routes>
                            {MainRouter()}
                            <Route path="*" element={<NotFound />}/>
                        </Routes>
                    )
                    : (
                        <Routes>
                            <Route element={<LoginContainer/>}>
                                <Route path="/" element={<Login/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/forgot" element={<Forgot/>}/>
                                <Route
                                    path="/forgot-success"
                                    element={<ForgotSuccess/>}
                                />
                                <Route path="/recupera" element={<Login/>}/>
                                <Route
                                    path="/reset-password/:token"
                                    element={<Update/>}
                                />
                                <Route
                                    path="/recupera-success"
                                    element={<UpdateSuccess/>}
                                />
                            </Route>
                        </Routes>
                    )}
            </Router>
        </Modal>
    );
};
