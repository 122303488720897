import {useEffect, useState} from 'react';
import {useDrawer} from '../../shared/drawer';
import {PageBody} from '../../elements/page-body';
import {CodigosPostalesForm} from './codigos-postales-form';
import {Table} from "../../elements/table";
import {CodigosPostalesFilter} from './codigos-postales-filter';

export const CodigosPostales = () => {
    const {open, close} = useDrawer();
    const [refresh, setRefresh] = useState<string | null>(null);
    const [filters, setFilters] = useState<any>({});
    const [query, setQuery] = useState<any>(new URLSearchParams(filters));

    const handleNewAction = () => {
        open('cp.form.new-item', <CodigosPostalesForm close={handleClose}/>);
    };

    const handleClose = () => {
        setRefresh(Date.now().toString());
        close();
    };

    const handleFilters = () => {
        open(
            'common.filter.title',
            <CodigosPostalesFilter close={close} doFilter={doFilter} item={filters}/>
        );
    };

    const doFilter = (filters: any) => {
        close();
        setFilters(filters);
    };

    useEffect(() => {
        setQuery(new URLSearchParams(filters));
    }, [filters]);

    return (
        <PageBody
            newAction={handleNewAction}
            title={'cp.text.title'}
            newActionTitle={'cp.button.new'}
            filterAction={handleFilters}
            filters={filters}
        >
            <Table
                path="/codigos-postales"
                deletePath="/codigos-postales"
                query={query}
                callBeforeDrawerClosed={handleClose}
                Form={CodigosPostalesForm}
                withPagination
                refresh={refresh}
                headers={[
                    {
                        key: 'id',
                        label: 'common.form-label.id',
                    },
                    {
                        key: 'name',
                        label: 'common.form-label.name',
                    },
                    {
                        key: 'code',
                        label: 'common.form-label.code',
                    },
                    {
                        key: 'provincia',
                        label: 'common.form-label.provincia',
                    },
                    {
                        key: 'comarca',
                        label: 'common.form-label.comarca',
                    },
                ]}
            />
        </PageBody>
    );
};
