import React from 'react';
import {Slide, ToastContainer} from 'react-toastify';
import {Outlet} from 'react-router-dom';
import login from '../../assets/images/login.jpg';

export const LoginContainer = () => (
    <div>
        <div className="flex flex-row w-screen">
            <div className={'hidden md:flex md:w-1/2 h-screen'}>
                <div
                    className={
                        'bg-sky-700 opacity-10 md:w-1/2 h-screen mix-blend-normal absolute'
                    }
                />
                <img
                    className={'w-full h-screen object-cover object-right'}
                    src={login}
                    alt=""
                />
            </div>
            <div className={'w-full md:w-1/2'}>
                <Outlet/>
            </div>
        </div>

        <ToastContainer
            position="top-center"
            hideProgressBar
            autoClose={2000}
            transition={Slide}
            closeOnClick
        />
    </div>
);
