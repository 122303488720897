export const noop = (...args: any[]) => null;

export const classNames = (...classes: any[]) =>
    classes.filter(Boolean).join(' ');

export const getQueryString = (filters: any) => {
    return Object.keys(filters)
        .map((key) => `${key}=${filters[key] ? filters[key] : ''}`)
        .join('&');
};

export const formatFecha = (fecha: any) => {
    const fragmentos = fecha.split('-');
    return `${fragmentos[2]}/${fragmentos[1]}/${fragmentos[0]}`;
}

export const estatusColor = (item: any) => {
    switch (item.estado) {
        case 'PENDIENTE':
            return '#C9C9C990';
        case 'ASIGNADO':
            return '#7BC0D090';
        case 'PROCESO':
            return '#D0B87B90';
        case 'FINALIZADO':
            return '#7DD07B90';
        case 'CERRADO':
            return '#D07B7B90';
        default:
            return '#C9C9C990';
    }
};

export const estatusCardColor = (item: any) => {
    switch (item?.estado || item) {
        case 'CANCELADO':
            return 'bg-pendiente';
        case 'CONFIRMADO':
            return 'bg-asignado';
        case 'PENDIENTE':
            return 'bg-danger   ';
        case 'FINALIZADO':
            return 'bg-finalizado';
        case 'CERRADO':
            return 'bg-cerrado';
        case 'LEAD':
            return 'bg-pendiente';
        case 'CLIENTE':
            return 'bg-cerrado';
        default:
            return 'bg-pendiente';
    }
};

export const estatusTextColor = (item: any) => {
    switch (item?.estado) {
        case 'PENDIENTE':
            return '#545454';
        case 'ASIGNADO':
            return '#2F7781';
        case 'PROCESO':
            return '#B9772B';
        case 'FINALIZADO':
            return '#2F8133';
        case 'CANCELADO':
            return '#950F0F';
        case 'LEAD':
            return '#545454';
        case 'CLIENTE':
            return '#950F0F';
        default:
            return '#545454';
    }
};

export const estatusTextColorRendered = (item: any) => {
    switch (item?.estado || item) {
        case 'CANCELADO':
            return 'text-[#545454]';
        case 'CONFIRMADO':
            return 'text-[#FFFFFF]';
        case 'PENDIENTE':
            return 'text-[#FFFFFF]';
        case 'FINALIZADO':
            return 'text-[#2F8133]';
        case 'CERRADO':
            return 'text-[#950F0F]';
        case 'LEAD':
            return 'text-[#545454]';
        case 'CLIENTE':
            return 'text-[#950F0F]';
        default:
            return 'text-[#545454]';
    }
};
