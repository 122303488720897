import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../../../shared/http-client';
import { FieldError } from '../../../../elements/field-error/field-error';
import { FileInputElement } from '../../../../elements/file-input';
import { Button } from '../../../../elements/button';
import { useParams } from 'react-router-dom';

// construccion del objeto yup de validacion del cuestionario
let obligado = {
    // plano: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    file: null
};

export const ArchivosResiduosForm = ({
    item,
    close
}: {
    item?: any;
    close: () => void;
}) => {
    // console.log('USUARIOS FORM :: EDIT ITEM', item);

    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);
    const params = useParams();

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        return { ...values };
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        // console.log('ENVIO', enviaremos);

        let formdata = new FormData();
        // if (enviaremos.file) {
        //     if (enviaremos.file.file) {
        //         formdata.append(
        //             'file',
        //             enviaremos.file.file[0],
        //             enviaremos.file.file[0].name
        //         );
        //     }
        // }

        api(
            `/proyectos/${params.id}/importa_residuo_excavacion/`,
            'POST',
            formdata,
            true
        );
    };

    useEffect(() => {
        // console.log('STATE DATA', state);

        if (state.data) {
            if (state.path.includes('importa_residuo_excavacion')) {
                let formdata = new FormData();
                api(
                    `/proyectos/${params.id}/importa_residuo_otros/`,
                    'POST',
                    formdata,
                    true
                );
            }
            if (state.path.includes('importa_residuo_otros')) {
                // let formdata = new FormData();
                // api(
                //     `/proyectos/${params.id}/importa_presupuesto_residuo/`,
                //     'POST',
                //     formdata,
                //     true
                // );
                toast.success(`${t('common.label.success')}`);
                close();
            }
            if (state.path.includes('importa_presupuesto_residuo')) {
                toast.success(`${t('common.label.success')}`);
                close();
            }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validacion}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    {/*<pre>{JSON.stringify(item, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                    <Button
                        label={t('common.label.calcula_residuos')}
                        onClick={handleSubmit}
                    />

                    <FieldError
                        touched={touched}
                        errors={errors}
                        field={'file'}
                    />
                </form>
            )}
        </Formik>
    );
};
