import { useState } from 'react';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import { Table } from '../../../../elements/table';
import { useNavigate, useParams } from 'react-router-dom';
import { getQueryString } from '../../../../utils';
// import {ProyectoForm} from "./proyecto.form";

export const ArchivosResiduosTableComponent = ({
    refresh
}: {
    refresh: string | null;
}) => {
    dayjs.extend(localeData);
    dayjs.extend(weekday);
    dayjs.extend(isoWeek);

    const { id } = useParams();

    const navigate = useNavigate();

    return (
        <div className="flex h-full flex-col">
            <Table
                path="/residuos-excavacion"
                query={`proyecto=${id}`}
                compressed
                refresh={refresh}
                noEdit
                noDelete
                headers={[
                    {
                        key: 'name',
                        label: 'common.label.name'
                    },
                    {
                        key: 'densidad',
                        label: 'common.label.densidad'
                    },
                    {
                        key: 'volumen',
                        label: 'common.label.volumen'
                    },
                    {
                        key: 'volumen_aparente',
                        label: 'common.label.volumen_aparente'
                    },
                    {
                        key: 'peso',
                        label: 'common.label.peso'
                    },
                    {
                        key: 'preu_tona',
                        label: 'common.label.preu_tona'
                    },
                    {
                        key: 'preu',
                        label: 'common.label.preu'
                    }
                ]}
            />
        </div>
    );
};
