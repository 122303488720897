import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../../../shared/http-client';
import { FieldError } from '../../../../elements/field-error/field-error';
import { FileInputElement } from '../../../../elements/file-input';
import { Button } from '../../../../elements/button';

// construccion del objeto yup de validacion del cuestionario
let obligado = {
    // plano: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    plano: null
};

export const PlanosPlanoForm = ({
    item,
    close
}: {
    item?: any;
    close: () => void;
}) => {
    // console.log('USUARIOS FORM :: EDIT ITEM', item);

    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        const temp = { ...values };
        return { ...temp };
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        // console.log('ENVIO', enviaremos)

        let formdata = new FormData();
        if (enviaremos.plano) {
            if (enviaremos.plano.plano) {
                formdata.append(
                    'plano',
                    enviaremos.plano.plano[0],
                    enviaremos.plano.plano[0].name
                );
            }
        }

        if (data.id) {
            // update
            api(`/proyectos/${data.id}/`, 'PATCH', formdata, true);
        } else {
            // create
            api('/proyectos/', 'POST', formdata, true);
        }
    };

    useEffect(() => {
        // console.log('STATE DATA', state);

        if (state.data) {
            close();
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validacion}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    {/*<pre>{JSON.stringify(item, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                    {/*<Button*/}
                    {/*    block*/}
                    {/*    dataTestId={'save-button'}*/}
                    {/*    onClick={handleSubmit}*/}
                    {/*    disabled={isSubmitting || !values?.plano}*/}
                    {/*    label={t('common.button.save')}*/}
                    {/*/>*/}

                    <FileInputElement
                        name="plano"
                        accept={'application/pdf'}
                        onChange={(value) => {
                            setFieldValue('plano', value);
                            handleSubmit();
                        }}
                    />

                    <FieldError
                        touched={touched}
                        errors={errors}
                        field={'plano'}
                    />
                </form>
            )}
        </Formik>
    );
};

