import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageBody } from '../../../../elements/page-body';
import { useDrawer } from '../../../../shared/drawer';
import { ArchivosCaidaForm } from './archivos-caida-form';
import { ArchivosCaidaTableComponent } from './archivos-caida-table.component';
import { ArchivosPresupuestoForm } from './archivos-presupuesto-form';
import { ArchivosPresupuestoTableComponent } from './archivos-presupuesto-table.component';
import { ArchivosPresupuestoTotalesComponent } from './archivos-presupuesto-totales.component';
import { ArchivosResiduosTableComponent } from './archivos-residuos-excavacion-table.component';
import { ArchivosResiduosForm } from './archivos-residuos-form';
import { ArchivosResiduosOtrosTableComponent } from './archivos-residuos-otros-table.component';

export const ProyectoArchivosComponent = () => {
    const { t } = useTranslation();
    const { open, close } = useDrawer();

    const [refresh, setRefresh] = useState<string | null>(null);

    const handleClose = () => {
        // api(`/intervenciones/${id}`, "GET")
        setRefresh(Date.now().toString());
    };

    return (
        <div className="flex h-full flex-col">
            <PageBody title={t('proyectos.tabs.archivos')}>
                <div>
                    <hr className="my-8" />

                    <div className="flex flex-col justify-start">
                        <h1 className="text-2xl font-bold mb-4">
                            {t('common.label.caida')}
                        </h1>

                        <ArchivosCaidaForm close={handleClose} />
                        <h1 className="text-xl my-4">
                            {t('common.label.caida')}
                        </h1>
                        <ArchivosCaidaTableComponent refresh={refresh} />
                    </div>

                    <hr className="my-8" />

                    <h1 className="text-2xl font-bold mb-4">
                        {t('common.label.residuos')}
                    </h1>

                    <div className="flex flex-col justify-start">
                        <ArchivosResiduosForm close={handleClose} />
                        <div className="flex flex-row justify-start space-x-4">
                            <div className="flex-1">
                                <h1 className="text-xl my-4">
                                    {t('common.label.residuos-excavacion')}
                                </h1>
                                <ArchivosResiduosTableComponent
                                    refresh={refresh}
                                />
                            </div>
                            <div className="flex-1">
                                <h1 className="text-xl my-4">
                                    {t('common.label.residuos-otros')}
                                </h1>
                                <ArchivosResiduosOtrosTableComponent
                                    refresh={refresh}
                                />
                            </div>
                        </div>
                    </div>

                    <hr className="my-8" />

                    <h1 className="text-2xl font-bold mb-4">
                        {t('common.label.presupuesto')}
                    </h1>

                    <h1 className="text-xl mb-4">
                        {t('common.label.presupuesto-form')}
                    </h1>
                    <ArchivosPresupuestoForm close={handleClose} />
                    <h1 className="text-xl my-4">
                        {t('common.label.presupuesto')}
                    </h1>
                    <ArchivosPresupuestoTotalesComponent />
                    <ArchivosPresupuestoTableComponent refresh={refresh} />
                </div>
            </PageBody>
        </div>
    );
};
