import { useEffect, useState } from 'react';
import { useDrawer } from '../../shared/drawer';
import { PageBody } from '../../elements/page-body';
import { UsuariosForm } from './usuarios-form';
import { Table } from "../../elements/table";
import { UsuariosFilter } from './usuarios-filter';

export const Usuarios = () => {
    const { open, close } = useDrawer();
    const [refresh, setRefresh] = useState<string | null>(null);
    const [filters, setFilters] = useState<any>({});
    const [query, setQuery] = useState<any>(new URLSearchParams(filters));

    const handleNewAction = () => {
        open('usuarios.form.new-item', <UsuariosForm close={handleClose} />);
    };

    const handleClose = () => {
        setRefresh(Date.now().toString());
        close();
    };

    const handleFilters = () => {
        open(
            'common.filter.title',
            <UsuariosFilter close={close} doFilter={doFilter} item={filters} />
        );
    };

    const doFilter = (filters: any) => {
        close();
        setFilters(filters);
    };

    useEffect(() => {
        setQuery(new URLSearchParams(filters));
    }, [filters]);

    return (
        <PageBody
            newAction={handleNewAction}
            title={'usuarios.text.title'}
            newActionTitle={'usuarios.button.new'}
            filterAction={handleFilters}
            filters={filters}
        >
            <Table
                path="/users"
                deletePath="/users"
                query={query}
                callBeforeDrawerClosed={handleClose}
                Form={UsuariosForm}
                withPagination
                refresh={refresh}
                headers={[
                    {
                        key: 'id',
                        label: 'common.form-label.id',
                    }, {
                        key: 'email',
                        label: 'common.form-label.email',
                    }, {
                        key: 'first_name',
                        label: 'common.form-label.first_name',
                    }, {
                        key: 'last_name',
                        label: 'common.form-label.last_name',
                    }, {
                        key: 'rol',
                        label: 'usuario.form.rol',
                    },
                ]}
            />
        </PageBody>
    );
};
