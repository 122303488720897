import {PageBody} from '../../elements/page-body';
import {HomeBody} from './home-body';

export const Home = () => {
    console.log('Home');
    return (
        <PageBody title={'Home'}>
            <HomeBody/>
        </PageBody>
    );
};
