import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Input } from '../../elements/input';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../shared/http-client';
import { FormFooter } from '../../elements/form-footer';
import { FieldError } from "../../elements/field-error/field-error";

// construccion del objeto yup de validacion del cuestionario
let obligado = {
    name: Yup.string().required('obligatorio'),
    code: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    name: '',
    code: '',
    provincia: '',
};

export const CodigosPostalesForm = ({
    item,
    close,
    doDelete
}: {
    item?: any;
    close: () => void;
    doDelete?: (item: any) => void;
}) => {
    // console.log('USUARIOS FORM :: EDIT ITEM', item);

    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        return { ...values };
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        // console.group('Enviamos');
        // console.log('values :>> ', values);
        // console.log(enviaremos);
        // console.groupEnd();

        if (enviaremos.id) {
            // update
            api(`/codigos-postales/${enviaremos.id}/`, 'PATCH', enviaremos);
        } else {
            // create
            api('/codigos-postales/', 'POST', enviaremos);
        }
    };

    useEffect(() => {
        // console.log('STATE DATA', state);

        if (state.data) {
            close();
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={data || initialValues}
            validationSchema={validacion}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    {/*<pre>{JSON.stringify(item, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                    <Input
                        label={t('common.form-label.name')}
                        type="text"
                        placeholder={t('common.form-label.name')}
                        name="name"
                        value={values.name}
                        onChange={(value) =>
                            setFieldValue('name', value.name)
                        }
                    />
                    <FieldError touched={touched} errors={errors} field={"name"} />

                    <Input
                        label={t('common.form-label.code')}
                        type="text"
                        placeholder={t('common.form-label.code')}
                        name="code"
                        value={values.code}
                        onChange={(value) =>
                            setFieldValue('code', value.code)
                        }
                    />
                    <FieldError touched={touched} errors={errors} field={"code"} />

                    <Input
                        label={t('common.form-label.provincia')}
                        type="text"
                        placeholder={t('common.form-label.provincia')}
                        name="provincia"
                        value={values.provincia}
                        onChange={(value) =>
                            setFieldValue('provincia', value.provincia)
                        }
                    />
                    <FieldError touched={touched} errors={errors} field={"provincia"} />

                    <Input
                        label={t('common.form-label.comarca')}
                        type="text"
                        placeholder={t('common.form-label.comarca')}
                        name="comarca"
                        value={values.comarca}
                        onChange={(value) =>
                            setFieldValue('comarca', value.comarca)
                        }
                    />
                    <FieldError touched={touched} errors={errors} field={"comarca"} />

                    <FormFooter
                        item={item}
                        doDelete={doDelete}
                        doSubmit={handleSubmit}
                        close={close}
                        isSubmitting={isSubmitting}
                    />
                </form>
            )}
        </Formik>
    );
};
