import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../../../shared/http-client';
import { FieldError } from '../../../../elements/field-error/field-error';
import { FileInputElement } from '../../../../elements/file-input';
import { Button } from '../../../../elements/button';
import { useParams } from 'react-router-dom';
import { CogIcon } from '@heroicons/react/outline';

// construccion del objeto yup de validacion del cuestionario
let obligado = {
    // plano: Yup.string().required('obligatorio'),
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    file: null
};

export const ArchivosCaidaForm = ({
    item,
    close
}: {
    item?: any;
    close: () => void;
}) => {
    // console.log('USUARIOS FORM :: EDIT ITEM', item);

    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const params = useParams();

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        return { ...values };
    };

    const onSubmit = async (values: any | null, { resetForm }: any) => {
        setIsLoading(true);
        //
        const enviaremos = beforeSubmit(values);

        // console.log('ENVIO', enviaremos);

        let formdata = new FormData();
        if (enviaremos.file) {
            if (enviaremos.file.file) {
                formdata.append(
                    'file',
                    enviaremos.file.file[0],
                    enviaremos.file.file[0].name
                );
            }
        }

        api(
            `/proyectos/${params.id}/importa_caida_tension/`,
            'POST',
            formdata,
            true
        );

        resetForm();
    };

    useEffect(() => {
        // console.log('STATE DATA', state);

        if (state.data) {
            if (state.path.includes('importa_caida_tension')) {
                toast.success(`${t('common.label.success')}`);
                close();
                setIsLoading(false);
            }
        }

        if (state.error) {
            console.log(state.error);
            toast.error(state.error.response);
            setIsLoading(false);
        }
    }, [state]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validacion}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    {/*<pre>{JSON.stringify(item, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                    {/* <FileInputElement */}
                    {/*     name="file" */}
                    {/*     accept={ */}
                    {/*         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' */}
                    {/*     } */}
                    {/*     onChange={(value) => { */}
                    {/*         setFieldValue('file', value); */}
                    {/*         handleSubmit(); */}
                    {/*     }} */}
                    {/* /> */}

                    <Button
                        label={t('common.label.calcula_caida')}
                        onClick={handleSubmit}
                    />

                    {isLoading ? (
                        <div className={'w-full flex justify-center my-4'}>
                            <CogIcon className="animate-spin h-5 w-5 text-gray-400" />
                        </div>
                    ) : null}

                    <FieldError
                        touched={touched}
                        errors={errors}
                        field={'file'}
                    />
                </form>
            )}
        </Formik>
    );
};

