import { useParams } from 'react-router-dom';
import { PageBody } from '../../../../elements/page-body';
import { useDrawer } from '../../../../shared/drawer';
import { useEffect, useState } from 'react';
import { useHttpClient } from '../../../../shared/http-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { BaseTemplateElection } from './base-template-election';

export const ProyectoBaseComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { open, close } = useDrawer();
    const { api, state } = useHttpClient();

    const [data, setData] = useState<any>(null);
    const [count, setCount] = useState<number>(0);

    useEffect(() => {
        if (id) {
            api(`/proyectos/${id}/`, 'GET');
        }
    }, [id]);

    const handleReload = () => {
        api(`/proyectos/${id}/`, 'GET');
    };

    useEffect(() => {
        if (state.data) {
            if (state.path.includes('proyectos')) {
                setData(state.data);
            }
        }

        if (state.error) {
            toast.error(state.error.detail.detail);
        }
    }, [state]);

    // const handleNewAction = () => {
    //     open(
    //         'intervenciones.drawer.add-equipo',
    //         <EquiposIntervencionForm
    //             item={intervencion}
    //             close={handleClose}
    //         />,
    //         false,
    //         "2xl"
    //     );
    // };
    //
    // const handleClose = () => {
    //     api(`/intervenciones/${id}`, "GET")
    //     close();
    // };
    //
    // const handleDelete = (itemId: number | string) => {
    //     api(`/equipos-intervencion/${itemId}/`, 'DELETE');
    //     api(`/intervenciones/${id}`, "GET")
    // };
    //
    // const handleSave = (id: number, item: any) => {
    //     if (id) {
    //         api(`/equipos-intervencion/${id}/`, 'PATCH', item);
    //     } else {
    //         api(`/equipos-intervencion/`, 'POST', item);
    //     }
    // };

    return (
        <div className="flex h-full flex-col">
            <PageBody>
                <div>
                    <BaseTemplateElection data={data} reload={handleReload} />
                </div>
            </PageBody>
        </div>
    );
};

