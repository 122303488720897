import React, {useEffect, useState} from 'react';
import {Slide, ToastContainer} from 'react-toastify';
import {Link, Outlet} from 'react-router-dom';
import {CogIcon, LockClosedIcon, LockOpenIcon, LogoutIcon} from '@heroicons/react/outline';
import {classNames} from '../utils';
import {MENU_NAVIGATION_LINKS} from './constants';
import {StoreKeys, useStore} from '../shared/store';
import {useTranslation} from 'react-i18next';
import {Disclosure} from '@headlessui/react';
import {Avatar} from '../elements/avatar';
import LOGO from '../../assets/images/logo.jpg';
import {animated, useSpring} from '@react-spring/web'
import {APP_NAME} from "../constants";

export const MenuContainer = () => {
    const {t} = useTranslation();
    const {remove, get} = useStore();
    const [profile, setProfile] = useState<any>({});
    const [path, setPath] = useState<string>('');
    const [isOpened, setIsOpened] = useState<boolean>(true);
    const props = useSpring({width: isOpened ? 288 : 80})

    const handelLogout = () => {
        remove();
        window.location.href = '/';
    };

    useEffect(() => {
        if (get(StoreKeys.Token)) {
            setProfile(get(StoreKeys.Profile));
        }
    }, []);

    return (
        <>
            <div className="flex flex-row h-screen">

                <animated.div
                    style={props}
                >
                    <animated.div className={classNames(
                        "relative flex flex-col h-screen bg-gray-light"
                    )}
                                  style={props}
                    >
                        <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                            <div className={"w-full pr-2 flex justify-end"}>
                                <button className={""}
                                        onClick={() => setIsOpened(!isOpened)}>
                                    {isOpened
                                        ? <LockClosedIcon className={"h-6 w-6 text-primary hover:text-secondary"}/>
                                        : <LockOpenIcon className={"h-6 w-6 text-primary hover:text-secondary"}/>
                                    }
                                </button>
                            </div>
                            {isOpened
                                ? <div className="flex-shrink-0 flex flex-col items-start px-4">
                                    <img
                                        className="h-16 w-auto"
                                        src={LOGO}
                                        alt="Workflow"
                                    />
                                    <div className={"text-xl font-bold"}>{APP_NAME}</div>
                                </div>
                                : null}
                            <nav className="mt-5 px-2 space-y-1">
                                {MENU_NAVIGATION_LINKS.map((item, index) =>
                                    !item.children
                                        ? (
                                            <div key={index}>
                                                {
                                                    item.roles && item.roles.find(i => i === profile.rol) ? (
                                                        <Link
                                                            key={item.name}
                                                            data-testid={`menu-${item.name}`}
                                                            onClick={() => setPath(item.href as string)}
                                                            to={item.href as string}
                                                            title={item.name}
                                                            className={classNames(
                                                                (window.location
                                                                        .pathname ===
                                                                    item.href || path === item.href)
                                                                    ? 'group flex items-center px-2 py-2 text-base font-medium rounded-md bg-gray-100 text-gray-900 hover:bg-gray-50 hover:text-gray-900'
                                                                    : 'group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                                isOpened ? "justify-start" : "justify-center"
                                                            )}

                                                        >
                                                            {item.icon ?
                                                                <item.icon
                                                                    className={"w-6 h-6 text-center text-primary mr-2"}/> : null}
                                                            {isOpened ? <>{t(item.name)}</> : null}
                                                        </Link>
                                                    ) : null
                                                }
                                            </div>
                                        )
                                        : (
                                            item.roles && item.roles.find(i => i === profile.rol) ? (

                                                <Disclosure
                                                    as="div"
                                                    key={item.name}
                                                    className="space-y-1"
                                                    defaultOpen={false}
                                                >
                                                    {({open}) => (
                                                        <>
                                                            <Disclosure.Button
                                                                data-testid={`menu-${item.name}`}
                                                                className={classNames(
                                                                    item.current
                                                                        ? 'bg-white text-gray-900'
                                                                        : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                                    'group w-full flex items-center pr-2 p-2 text-left text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                                                                )}
                                                            >
                                                                <svg
                                                                    className={classNames(
                                                                        open
                                                                            ? item.name !== "Informes"
                                                                                ? 'text-gray-400 rotate-90 '
                                                                                : 'text-gray-300'
                                                                            : 'text-gray-300 ',
                                                                        'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-300 transition-colors ease-in-out duration-150'
                                                                    )}
                                                                    viewBox="0 0 20 20"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        d="M6 6L14 10L6 14V6Z"
                                                                        fill={item.name !== "Informes" ? "currentColor" : "transparent"}
                                                                    />
                                                                </svg>
                                                                <span
                                                                    className={"text-xs uppercase leading-4"}
                                                                >
                                                                {isOpened ? <>{t(item.name)}</> :
                                                                    <CogIcon className={"h-4 w-4"}/>}
                                                            </span>
                                                            </Disclosure.Button>
                                                            <Disclosure.Panel
                                                                static={item.name === "Informes"}
                                                                className="space-y-1"
                                                            >
                                                                <>
                                                                    {item.children?.map(
                                                                        (
                                                                            subItem,
                                                                            index
                                                                        ) => (
                                                                            <div key={index}>
                                                                                {
                                                                                    item.roles && item.roles.find(i => i === profile.rol) ? (

                                                                                        <Link
                                                                                            key={subItem.name}
                                                                                            onClick={() => setPath(subItem.href as string)}
                                                                                            to={subItem.href as string}
                                                                                            title={subItem.name}
                                                                                            data-testid={`menu-${subItem.name}`}
                                                                                            className={classNames(
                                                                                                (window.location
                                                                                                        .pathname ===
                                                                                                    subItem.href || path === subItem.href)
                                                                                                    ? 'group w-full flex items-center pr-2 py-2 text-sm font-medium bg-white text-gray-900'
                                                                                                    : 'group w-full flex items-center pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50',
                                                                                                isOpened ? "justify-start pl-10 " : "justify-center pl-6"
                                                                                            )}
                                                                                        >
                                                                                            {subItem.icon ? <subItem.icon
                                                                                                className={"w-6 h-6 text-center text-primary mr-2"}/> : null}
                                                                                            {isOpened ? <>{t(subItem.name)}</> : null}
                                                                                        </Link>
                                                                                    ) : null
                                                                                }
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </>
                                                            </Disclosure.Panel>
                                                        </>
                                                    )}
                                                </Disclosure>
                                            ) : null
                                        )
                                )}
                            </nav>
                        </div>
                        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                            <Link
                                to="#"
                                className="flex-shrink-0 group block"
                            >
                                <div className="flex items-center">
                                    {profile && (
                                        <>
                                            <Avatar name={profile.name || profile.email}/>
                                            {isOpened
                                                ? <div className="ml-3">
                                                    <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                                                        {profile.name || profile.email}
                                                    </p>
                                                </div>
                                                : null}
                                        </>
                                    )}
                                </div>
                            </Link>
                        </div>
                        <div className="flex-shrink-0 flex border-t border-white p-4 hover:bg-gray-50">
                            <button
                                onClick={handelLogout}
                                className="flex space-x-1 items-center flex-shrink-0 group block "
                            >
                                <LogoutIcon className="h-5 w-5 text-gray-800 mx-2"/>
                                {isOpened
                                    ? <span>
                                    {t('common.button.logout')}
                                </span>
                                    : null}
                            </button>
                        </div>

                    </animated.div>
                </animated.div>

                <div className="flex flex-col flex-1 overflow-y-scroll">
                    <Outlet/>
                </div>
            </div>

            <ToastContainer
                position="top-center"
                hideProgressBar
                autoClose={2000}
                transition={Slide}
                closeOnClick
            />
        </>
    );
};
