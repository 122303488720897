export const INITIAL_FILTERS = {};

export const PROJECT_KEYS = [
    'seccion_cable',
    'datos',
    'lugar',
    'expediente',
    'potencia',
    'cliente',
    'tm',
    'cp',
    'titulo',
    'niter',
    'ads',
    'iter',
    'plano',
    'lateral'
];

export const TIPOS = [
    { id: 1, name: 'EMPLAMAMENT' },
    { id: 2, name: 'EMPLAMAMENTS' }
];
