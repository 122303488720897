import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Input } from '../../elements/input';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../shared/http-client';
import { FilterFooter } from '../../elements/filter-footer';
import { DateRangePicker } from '../../elements/date-range-picker';
import dayjs from 'dayjs';

// construccion del objecto yup de validacion del cuestionario
let obligado = {};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    name: '',
    expedientw: '',
    im3: '',
    fecha_after: '',
    fecha_before: ''
};

export const ProyectoFilter = ({
    item,
    doFilter,
    close
}: {
    item?: any;
    close: () => void;
    doFilter: (item: any) => void;
}) => {
    const { t } = useTranslation();
    const { api, state, options } = useHttpClient();
    const [data, setData] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<any | null>(null);
    const [endDate, setEndDate] = useState<any | null>(null);

    useEffect(() => {
        // api('/operarios/?is_active=true&limit=9999', 'GET');
    }, []);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        let after = { ...values };

        if (startDate) {
            after.modificado_after = dayjs(startDate).format('YYYY-MM-DD');
        }

        if (endDate) {
            after.modificado_before = dayjs(endDate).format('YYYY-MM-DD');
        }

        return after;
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        doFilter(enviaremos);
    };

    useEffect(() => {
        if (state.data) {
            // if (state.path.includes('operarios')) {
            //     setOperarios(state.data.results);
            // }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    const handleDates = (dates: any) => {
        if (dates) {
            const start = dayjs(dates[0]);
            const end = dates[1]
                ? dayjs(dates[1]).add(1, 'day')
                : dayjs(dates[0]).add(1, 'day');
            if (dates[1]) {
                setStartDate(`${start.format('YYYY-MM-DD')}`);
                setEndDate(`${end.format('YYYY-MM-DD')}`);
            }
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={data || initialValues}
                validationSchema={validacion}
                validateOnBlur={true}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue, handleSubmit }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                        {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                        <Input
                            name="name"
                            label={t('proyecto.form.name')}
                            type="text"
                            placeholder={t('proyecto.form.name')}
                            value={values.name}
                            onChange={(value) =>
                                setFieldValue('name', value.name)
                            }
                        />
                        <Input
                            name="im3"
                            label={t('proyecto.form.im3_1')}
                            type="text"
                            placeholder={t('proyecto.form.im3_1')}
                            value={values.im3}
                            onChange={(value) =>
                                setFieldValue('im3', value.im3)
                            }
                        />

                        <Input
                            name="expediente"
                            label={t('proyecto.form.expediente')}
                            type="text"
                            placeholder={t('proyecto.form.expediente')}
                            value={values.expediente}
                            onChange={(value) =>
                                setFieldValue('expediente', value.expediente)
                            }
                        />

                        <DateRangePicker doChange={handleDates} />

                        <FilterFooter
                            item={item}
                            doDelete={() => doFilter({})}
                            doSubmit={handleSubmit}
                            close={close}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
};

