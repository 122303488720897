export function PlanosDecodingItem(props: { decoded: any, clave: string }) {
    const {decoded, clave} = props
    return (
        <>
            {decoded?.textos?.[clave]
                ? <div className={"w-full bg-white p-8 shadow-md rounded-lg mb-8 flex flex-wrap flex-col space-y-4"}>
                    <img src={decoded?.imagenes?.[clave]} alt={clave}/>
                    <div
                        className={"text-[9px] whitespace-normal"}>{JSON.stringify(decoded?.textos?.[clave], null, 4)}</div>
                </div>
                : null
            }
        </>
    )
}
