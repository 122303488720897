import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../../shared/http-client';
import { FormFooter } from '../../../elements/form-footer';
import { StoreKeys, useStore } from '../../../shared/store';
import { SelectAsync } from '../../../elements/select-async';

// construccion del objeto yup de validacion del cuestionario
let obligado = {};
const validacion = Yup.object().shape(obligado);

const initialValues = {};

export const ProjectOrganismosForm = ({
    item,
    close,
    doDelete,
    isPage = false
}: {
    item?: any;
    close: () => void;
    doDelete?: (item: any) => void;
    isPage?: boolean;
}) => {
    // console.log('USUARIOS FORM :: EDIT ITEM', item);

    const { t } = useTranslation();
    const { id } = useParams();
    const { api, state, options } = useHttpClient();
    const [data, setData] = useState<any | null>(null);
    const [user, setUser] = useState<any | null>(null);

    const { get } = useStore();

    useEffect(() => {
        const user = get(StoreKeys.Profile);
        setUser(user);
    }, []);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        const after = { ...values };

        return after;
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        const aList = item.organismos.map((u: any) => u.id);
        aList.push(values.organismo.id);
        item.organismos = [...aList];

        // update
        api(`/proyectos/${enviaremos.id}/`, 'PATCH', {
            organismos: [...aList]
        });
        setTimeout(() => close(), 1000);
    };

    useEffect(() => {
        // console.log('STATE DATA', state);

        if (state.data) {
            close();
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={data || initialValues}
            validationSchema={validacion}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting
            }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className="static"
                >
                    {/* <pre>{JSON.stringify(item, null, 4)}</pre> */}
                    {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}

                    <SelectAsync
                        name="organismo"
                        label={t('common.form-label.organismo')}
                        placeholder={t('common.button.search')}
                        items={async (value: string) => {
                            return await options(
                                `/organismos-afectados/?name=${value}`,
                                'GET'
                            );
                        }}
                        value={values.organismo}
                        optionsLabel={'name'}
                        optionalLabel={'text'}
                        onChange={(value: any) => {
                            setFieldValue('organismo', value);
                        }}
                    />

                    <FormFooter
                        item={item}
                        doDelete={doDelete}
                        doSubmit={handleSubmit}
                        close={close}
                    />
                </form>
            )}
        </Formik>
    );
};

