import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Input } from '../../elements/input';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../shared/http-client';
import { FormFooter } from '../../elements/form-footer';
import { FieldError } from "../../elements/field-error/field-error";
import {roles} from "./constants";
import {Select} from "../../elements/select";

// construccion del objeto yup de validacion del cuestionario
let obligado = {
    email: Yup.string().email('must be email').required('obligatorio'),
    first_name: Yup.string().required('obligatorio'),
    last_name: Yup.string().required('obligatorio'),
    password: Yup.string().when('password', (val) => {
        if (val) {
            return Yup.string().required().min(8, 'Min 8 characters');
        } else {
            return Yup.string().notRequired();
        }
    })
};
const validacion = Yup.object().shape(obligado, [['password', 'password']]);

const initialValues = {
    email: '',
    first_name: '',
    last_name: ''
};

export const UsuariosForm = ({
    item,
    close,
    doDelete
}: {
    item?: any;
    close: () => void;
    doDelete?: (item: any) => void;
}) => {
    // console.log('USUARIOS FORM :: EDIT ITEM', item);

    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {

        const after = {...values}
        after.rol = roles.find((rol) => rol.id === values.rol)?.rol || "usuario";

        return after;
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        // console.group('Enviamos');
        // console.log('values :>> ', values);
        // console.log(enviaremos);
        // console.groupEnd();

        if (enviaremos.id) {
            // update
            enviaremos.username = enviaremos.email;
            api(`/users/${enviaremos.id}/`, 'PATCH', enviaremos);
        } else {
            // create
            enviaremos.username = enviaremos.email;
            api('/users/', 'POST', enviaremos);
        }
    };

    useEffect(() => {
        // console.log('STATE DATA', state);

        if (state.data) {
            close();
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={data || initialValues}
            validationSchema={validacion}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    {/*<pre>{JSON.stringify(item, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                    <Input
                        label={t('usuario.form.email')}
                        type="email"
                        placeholder={t('usuario.form.email')}
                        name="email"
                        value={values.email}
                        onChange={(value) =>
                            setFieldValue('email', value.email)
                        }
                    />
                    <FieldError touched={touched} errors={errors} field={"email"} />

                    <Input
                        label={t('usuario.form.first_name')}
                        type="text"
                        placeholder={t('usuario.form.first_name')}
                        name="first_name"
                        value={values.first_name}
                        onChange={(value) =>
                            setFieldValue('first_name', value.first_name)
                        }
                    />
                    <FieldError touched={touched} errors={errors} field={"first_name"} />

                    <Input
                        label={t('usuario.form.last_name')}
                        type="text"
                        placeholder={t('usuario.form.last_name')}
                        name="last_name"
                        value={values.last_name}
                        onChange={(value) =>
                            setFieldValue('last_name', value.last_name)
                        }
                    />
                    <FieldError touched={touched} errors={errors} field={"last_name"} />

                    {!item && (
                        <div>
                            <Input
                                label={t('usuario.form.password')}
                                type="password"
                                placeholder={t('usuario.form.password')}
                                name="password"
                                value={values.password}
                                onChange={(value) =>
                                    setFieldValue('password', value.password)
                                }
                            />
                            <FieldError touched={touched} errors={errors} field={"password"} />
                        </div>
                    )}

                    <Select
                        label={t('usuario.form.rol')}
                        noMargin
                        name={"rol"}
                        placeholder={t('usuario.form.rol')}
                        onChange={(value) =>
                            setFieldValue('rol', value.rol)
                        }
                        value={values.rol === "usuario" || values.rol === "admin"
                            ? roles.find((rol: any) => rol.rol === values.rol)
                            : values.rol
                        }
                        items={roles}
                    />

                    <FormFooter
                        item={item}
                        doDelete={doDelete}
                        doSubmit={handleSubmit}
                        close={close}
                    />
                </form>
            )}
        </Formik>
    );
};
