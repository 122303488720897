import { Route } from 'react-router-dom';

import { Main } from './main';
import { MenuContainer } from '../../layouts';
import { Home } from '../home';
import { Usuarios } from '../usuarios/usuarios';
import { Proyectos } from '../proyectos/proyectos';
import { ProyectosDetail } from '../proyectos/proyectos-detail';
import { ProyectoPlanosComponent } from '../proyectos/tabs/planos/proyecto.planos.component';
import { ProyectoArchivosComponent } from '../proyectos/tabs/archivos/proyecto.archivos.component';
import { ProyectoBaseComponent } from '../proyectos/tabs/base/proyecto.base.component';
import { CodigosPostales } from '../codigos-postales/codigos-postales';
import { Residuos } from '../residuos/residuos';
import { Templates } from '../templates/templates';
import { Organismos } from '../organismos/organismos';
import { Servicios } from '../servicios/servicios';

export const MainRouter = () => {
    return (
        <Route element={<MenuContainer />}>
            <Route path="/" element={<Main />}>
                {/* <Route index element={<Home/>}/> */}
                {/* <Route path="home" element={<Home/>}/> */}

                <Route index element={<Proyectos />} />
                <Route path="proyectos">
                    <Route index element={<Proyectos />} />
                    <Route path=":id" element={<ProyectosDetail />}>
                        <Route
                            path="planos"
                            element={<ProyectoPlanosComponent />}
                        />
                        <Route
                            path="archivos"
                            element={<ProyectoArchivosComponent />}
                        />
                        <Route
                            path="base"
                            element={<ProyectoBaseComponent />}
                        />
                    </Route>
                </Route>

                <Route path="codigos-postales" element={<CodigosPostales />} />
                <Route path="organismos" element={<Organismos />} />
                <Route path="servicios" element={<Servicios />} />
                <Route path="usuarios" element={<Usuarios />} />
                <Route path="residuos" element={<Residuos />} />
                <Route path="templates" element={<Templates />} />

                <Route path="*" element={<Home />} />
            </Route>
        </Route>
    );
};

