import { useParams } from 'react-router-dom';
import { PageBody } from '../../../../elements/page-body';
import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../../../shared/http-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { PROJECT_KEYS } from '../../constants';
import { CogIcon } from '@heroicons/react/outline';
import { PlanosPlanoForm } from './planos-plano-form';
import { PlanosDecodingItem } from './planos-decoding-item';
import { PlanosPlanoComponent } from './planos-plano-component';
import { PlanosDecodingForm } from './planos-decoding-form';

export const ProyectoPlanosComponent = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { api, state } = useHttpClient();

    const [data, setData] = useState<any>(null);
    const [decoded, setDecoded] = useState<any>(null);
    const [leido, setLeido] = useState<any>(false);

    useEffect(() => {
        if (id) {
            api(`/proyectos/${id}/`, 'GET');
        }
    }, [id]);

    useEffect(() => {
        // console.log("STATE IN PARTE :: ", state)

        if (state.data) {
            if (state.path.includes('proyectos')) {
                if (state.path.includes('procesa_plano')) {
                    api(`/proyectos/${id}/`, 'GET');
                    setDecoded(null);
                } else {
                    setData(state.data);

                    const decoded_data: { imagenes: any; textos: any } = {
                        imagenes: [],
                        textos: []
                    };
                    decoded_data.imagenes.datos =
                        state?.data?.datos_plano ?? null;
                    decoded_data.imagenes.lugar =
                        state?.data?.lugar_plano ?? null;
                    decoded_data.imagenes.logo =
                        state?.data?.logo_plano ?? null;
                    decoded_data.imagenes.expediente =
                        state?.data?.expediente_plano ?? null;
                    decoded_data.imagenes.potencia =
                        state?.data?.potencia_plano ?? null;
                    decoded_data.imagenes.cliente =
                        state?.data?.cliente_plano ?? null;
                    decoded_data.imagenes.tm = state?.data?.tm_plano ?? null;
                    decoded_data.imagenes.cp = state?.data?.cp_plano ?? null;
                    decoded_data.imagenes.titulo =
                        state?.data?.titulo_plano ?? null;
                    decoded_data.imagenes.voltaje =
                        state?.data?.voltaje ?? null;
                    decoded_data.imagenes.niter =
                        state?.data?.niter_plano ?? null;
                    decoded_data.imagenes.ads = state?.data?.ads_plano ?? null;
                    decoded_data.imagenes.iter =
                        state?.data?.iter_plano ?? null;
                    decoded_data.imagenes.fecha =
                        state?.data?.fecha_plano ?? null;
                    decoded_data.imagenes.etrs =
                        state?.data?.etrs_plano ?? null;
                    decoded_data.imagenes.plano =
                        state?.data?.plano_plano ?? null;
                    decoded_data.imagenes.lateral =
                        state?.data?.lateral_plano ?? null;
                    decoded_data.imagenes.seccion_cable =
                        state?.data?.seccion_cable_plano ?? null;

                    decoded_data.textos.datos = state?.data?.datos_data ?? null;
                    decoded_data.textos.lugar = state?.data?.lugar_data ?? null;
                    decoded_data.textos.direccion =
                        state?.data?.direccion ?? null;
                    decoded_data.textos.logo = state?.data?.logo_data ?? null;
                    decoded_data.textos.servicio =
                        state?.data?.servicio ?? null;
                    decoded_data.textos.expediente =
                        state?.data?.expediente_data ?? null;
                    decoded_data.textos.potencia =
                        state?.data?.potencia_data ?? null;
                    decoded_data.textos.cliente =
                        state?.data?.cliente_data ?? null;
                    decoded_data.textos.tm = state?.data?.tm_data ?? null;
                    decoded_data.textos.cp = state?.data?.cp_data ?? null;
                    decoded_data.textos.titulo =
                        state?.data?.titulo_data ?? null;
                    decoded_data.textos.voltaje = state?.data?.voltaje ?? null;
                    decoded_data.textos.niter = state?.data?.niter_data ?? null;
                    decoded_data.textos.ads = state?.data?.ads_data ?? null;
                    decoded_data.textos.iter = state?.data?.iter_data ?? null;
                    decoded_data.textos.fecha = state?.data?.fecha_data ?? null;
                    decoded_data.textos.etrs = state?.data?.etrs_data ?? null;
                    decoded_data.textos.plano = state?.data?.plano_data ?? null;
                    decoded_data.textos.lateral =
                        state?.data?.lateral_data ?? null;
                    decoded_data.textos.seccion_cable =
                        state?.data?.seccion_cable_data ?? null;
                    setDecoded(decoded_data);
                    setLeido(false);
                }
            }
        }

        if (state.error) {
            console.log('ERROR', state.error);
            toast.error(state.error);
        }
    }, [state]);

    useEffect(() => {
        if (!decoded) {
            setLeido(true);
        }
    }, [decoded]);

    const handleClose = () => {
        // api(`/proyectos/${id}/`, "GET")
        decode();
    };

    const decode = () => {
        setDecoded(null);
        api(`/proyectos/${id}/procesa_plano/`, 'GET');
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className="flex h-full flex-col">
            <PageBody noMargin>
                <div className={'grid grid-cols-1 xl:grid-cols-3 gap-x-4'}>
                    {/*formulario con el plano*/}
                    <div>
                        <div className="text-xl font-bold mb-2">
                            Carga del plano
                        </div>

                        <div>
                            <PlanosPlanoForm item={data} close={handleClose} />
                        </div>

                        <div className="text-xl font-bold mt-4">Resultado</div>

                        {!leido ? null : (
                            <div
                                className={
                                    'my-4 py-4 text-center bg-gray-100 rounded-md'
                                }
                            >
                                {t('proyectos.tabs.imagenes.decoding')}
                            </div>
                        )}
                        {/* <PlanosPlanoComponent data={data} /> */}
                        {/* </div> */}

                        {/*formulario con los detalles de lectura de los planos*/}
                        {/* <div> */}
                        {/*<Button*/}
                        {/*    block*/}
                        {/*    onClick={decode}*/}
                        {/*    label={t("proyectos.tabs.imagenes.decode")}*/}
                        {/*    disabled={state.isLoading || !data?.plano}*/}
                        {/*/>*/}
                        {!leido ? (
                            decoded ? (
                                PROJECT_KEYS.map((key: string, idx: number) => {
                                    return (
                                        <PlanosDecodingItem
                                            key={idx}
                                            decoded={decoded}
                                            clave={key}
                                        />
                                    );
                                })
                            ) : (
                                <div
                                    className={
                                        'w-full flex justify-center my-4'
                                    }
                                >
                                    <CogIcon className="animate-spin h-5 w-5 text-gray-400" />
                                </div>
                            )
                        ) : state.isLoading ? (
                            <div className={'w-full flex justify-center my-4'}>
                                <CogIcon className="animate-spin h-5 w-5 text-gray-400" />
                            </div>
                        ) : null}
                    </div>

                    {/*formulario con los textos ya decodificados*/}
                    <div className="col-span-2">
                        <PlanosDecodingForm item={data} close={handleRefresh} />
                    </div>
                </div>
            </PageBody>
        </div>
    );
};
