import { useEffect, useState } from 'react';
import { useDrawer } from '../../shared/drawer';
import { PageBody } from '../../elements/page-body';
import { OrganismosForm } from './organismos-form';
import { Table } from '../../elements/table';
import { OrganismosFilter } from './organismos-filter';

export const Organismos = () => {
    const { open, close } = useDrawer();
    const [refresh, setRefresh] = useState<string | null>(null);
    const [filters, setFilters] = useState<any>({});
    const [query, setQuery] = useState<any>(new URLSearchParams(filters));

    const handleNewAction = () => {
        open(
            'organismos.form.new-item',
            <OrganismosForm close={handleClose} />
        );
    };

    const handleClose = () => {
        setRefresh(Date.now().toString());
        close();
    };

    const handleFilters = () => {
        open(
            'common.filter.title',
            <OrganismosFilter
                close={close}
                doFilter={doFilter}
                item={filters}
            />
        );
    };

    const doFilter = (filters: any) => {
        close();
        setFilters(filters);
    };

    useEffect(() => {
        setQuery(new URLSearchParams(filters));
    }, [filters]);

    return (
        <PageBody
            newAction={handleNewAction}
            title={'organismos.text.title'}
            newActionTitle={'organismos.button.new'}
            filterAction={handleFilters}
            filters={filters}
        >
            <Table
                path="/organismos-afectados"
                deletePath="/organismos-afectados"
                query={query}
                callBeforeDrawerClosed={handleClose}
                Form={OrganismosForm}
                withPagination
                refresh={refresh}
                headers={[
                    {
                        key: 'id',
                        label: 'common.form-label.id'
                    },
                    {
                        key: 'name',
                        label: 'common.form-label.name'
                    },
                    {
                        key: 'text',
                        label: 'common.form-label.text'
                    }
                ]}
            />
        </PageBody>
    );
};

