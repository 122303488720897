import { useTranslation } from 'react-i18next';

export const FieldError = ({
    touched,
    errors,
    field
}: {
    touched: any;
    errors: any;
    field: string;
}) => {
    const { t } = useTranslation();

    return (
        touched[field] &&
        errors[field] && (
            <div
                className="w-full text-red-600 font-bold text-[12px] text-right mt-[-16px]"
                id="feedback"
            >
                {t(errors?.[field] as any)}
            </div>
        )
    );
};
