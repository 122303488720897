import React, {useEffect, useState} from 'react';
import {useHttpClient} from "../../shared/http-client";
import {toast} from "react-toastify";
import {renderTableItem} from "../table-item/table-item.element";
import {useNavigate} from "react-router-dom";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {noop} from "../../utils";

export const PageSubHeader = ({
                                  path,
                                  headers = [],
                                  doBackAction = noop,
                                  refresh = noop(),
                              }: {
    path: string,
    headers?: any[],
    doBackAction?: () => void,
    refresh?: string | null
}) => {

    const navigate = useNavigate();
    const {api, state} = useHttpClient();
    const [data, setData] = useState<any[]>([]);

    const request = () => {
        if (path) {
            //let endpoint = `${path}`;
            api(path, 'GET');
        }
    };

    useEffect(() => {
        request();
    }, []);

    useEffect(() => {
        if (refresh) {
            request();
        }
    }, [refresh]);

    useEffect(() => {
        if (state.data) {
            // console.log('TableElement :: UseEffect StateData => ', state.data);
            setData(state.data);
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <div
            className="flex items-center w-full py-1 mb-2 space-x-4 rounded-md bg-gray-50 shadow-md">
            {doBackAction
                ? <div
                    className="flex items-center justify-center cursor-pointer ml-4 hover:bg-gray-200 p-2 rounded-full"
                    onClick={() => {
                        doBackAction();
                    }}>
                    <ArrowLeftIcon className="w-4 h-4 text-gray-400"/>
                </div>
                : null}
            <table>
                <tbody>
                <tr className="">
                    {
                        renderTableItem(
                            data,
                            headers,
                            'pr-3 text-left text-gray-800 sm:pl-6 text-[11px]'
                        )
                    }
                </tr>
                </tbody>
            </table>
        </div>
    );
};
