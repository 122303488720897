import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Input } from '../../elements/input';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../shared/http-client';
import { FormFooter } from '../../elements/form-footer';
import { FieldError } from '../../elements/field-error/field-error';
import { FileInputElement } from '../../elements/file-input';
import { TextArea } from '../../elements/text-area';
import { Select } from '../../elements/select';
import { PLANTILLAS } from './constants';

// construccion del objeto yup de validacion del cuestionario
let obligado = {
    name: Yup.string().required('obligatorio')
};
const validacion = Yup.object().shape(obligado);

const initialValues = {
    name: '',
    plantilla: '',
    criterio: '',
    archivo: null
};

export const TemplatesForm = ({
    item,
    close,
    doDelete
}: {
    item?: any;
    close: () => void;
    doDelete?: (item: any) => void;
}) => {
    // console.log('USUARIOS FORM :: EDIT ITEM', item);

    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        if (item) {
            setData(item);
        }
    }, [item]);

    const beforeSubmit = (values: any | null) => {
        const after = { ...values };
        after.plantilla = PLANTILLAS.find(
            (item) => item.id === values.plantilla
        )?.id;

        return { ...after };
    };

    const onSubmit = async (values: any | null) => {
        //
        const enviaremos = beforeSubmit(values);

        let formdata = new FormData();
        if (enviaremos.archivo) {
            if (enviaremos.archivo.archivo) {
                formdata.append(
                    'archivo',
                    enviaremos.archivo.archivo[0],
                    enviaremos.archivo.archivo[0].name
                );
            }
        }

        for (let key in enviaremos) {
            if (key !== 'archivo') {
                formdata.append(key, enviaremos[key] ? enviaremos[key] : '');
            }
        }

        if (enviaremos.id) {
            // update
            api(`/templates/${enviaremos.id}/`, 'PATCH', formdata, true);
        } else {
            // create
            api('/templates/', 'POST', formdata, true);
        }
    };

    useEffect(() => {
        // console.log('STATE DATA', state);

        if (state.data) {
            close();
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={data || initialValues}
            validationSchema={validacion}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    {/*<pre>{JSON.stringify(item, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                    <Input
                        label={t('common.form-label.orden')}
                        type="number"
                        placeholder={t('common.form-label.orden')}
                        name="orden"
                        value={values.orden}
                        onChange={(value) =>
                            setFieldValue('orden', value.orden)
                        }
                    />

                    <Input
                        label={t('common.form-label.name')}
                        type="text"
                        placeholder={t('common.form-label.name')}
                        name="name"
                        value={values.name}
                        onChange={(value) => setFieldValue('name', value.name)}
                    />
                    <FieldError
                        touched={touched}
                        errors={errors}
                        field={'name'}
                    />

                    <Select
                        name="plantilla"
                        label={t('common.form-label.plantilla')}
                        placeholder={t('common.form-label.plantilla')}
                        items={PLANTILLAS}
                        value={values.plantilla}
                        onChange={(value: any) =>
                            setFieldValue('plantilla', value.plantilla)
                        }
                    />

                    {/*<TextArea*/}
                    {/*    label={t('common.form-label.criterio')}*/}
                    {/*    name="criterio"*/}
                    {/*    value={values.criterio}*/}
                    {/*    onChange={(value) => setFieldValue('criterio', value.criterio)}*/}
                    {/*/>*/}

                    <FileInputElement
                        name="archivo"
                        accept={
                            'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        }
                        onChange={(value) => setFieldValue('archivo', value)}
                    />
                    <FieldError
                        touched={touched}
                        errors={errors}
                        field={'archivo'}
                    />

                    {item?.archivo ? (
                        <div
                            className={
                                'my-4 py-4 text-center bg-gray-100 rounded-md'
                            }
                        >
                            {item.archivo?.split('/')[
                                item.archivo?.split('/').length - 1
                            ] ?? '-'}
                        </div>
                    ) : null}

                    <FormFooter
                        item={item}
                        doDelete={doDelete}
                        doSubmit={handleSubmit}
                        close={close}
                    />
                </form>
            )}
        </Formik>
    );
};

