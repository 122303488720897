import {Fragment, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid';

import {classNames, noop} from '../../utils';
import {Button} from "../button";
import {PlusIcon} from "@heroicons/react/outline";
import {useDrawer} from "../../shared/drawer";

export const Select = ({
                           name,
                           label,
                           disabled,
                           value = null,
                           items = [],
                           placeholder,
                           onChange = noop,
                           noMargin = false,
                           hasAdd = false,
                           addName = "",
                           AddForm
                       }: {
    name: string;
    label?: string;
    noMargin?: boolean;
    disabled?: boolean;
    hasAdd?: boolean;
    addName?: string | null;
    AddForm?: any;
    placeholder?: string;
    items: { id: number | string; name: string }[];
    value?: { id?: number | string; name?: string } | any;
    onChange: (item: { [key: string]: number | string } | any) => void;
}) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState<any>(value || items?.[0] || {});
    const {open, close} = useDrawer();

    useEffect(() => {
        if (value?.id) {
            onChange({[name]: value?.id || ""});
        }
    }, []);

    useEffect(() => {
        if (selected && Object.keys(selected).length) {
            // if (selected.id !== value.id && selected?.id) {
            if (selected?.id ?? null !== value?.id) {
                onChange({[name]: selected?.id});
            }
        }
    }, [selected]);

    useEffect(() => {
        if (value?.id) {
            setSelected(value);
            onChange({[name]: value.id});
        } else {
            const temp = items.find(
                (item: { id: string | number }) => item.id === +value
            );
            if (temp) {
                setSelected(temp);
            } else {
                setSelected(null);
            }
        }
    }, [value, items]);

    const handleAddNew = () => {
        console.log("add new")
        open(
            addName || "",
            AddForm,
            true,
        );

    }


    return (
        <div className={classNames('w-full', noMargin ? 'mb-0' : 'mb-[24px]')}>
            {selected !== undefined
                ? <div className={"flex flex-row w-full items-center"}>
                    <div className={"flex-1"}>
                        <Listbox
                            value={selected}
                            onChange={setSelected}
                            name={name}
                        >
                            {({open}) => (
                                <>
                                    <Listbox.Label className="block text-sm font-semibold text-gray-700">
                                        {label}
                                    </Listbox.Label>
                                    <div className="mt-3 relative">
                                        <Listbox.Button
                                            className={classNames(
                                                disabled
                                                    ? 'text-gray-darker bg-gray-light not-italic font-light text-sm leading-6 h-[40px] relative w-full border border-gray-dark shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-transparent focus:border-gray-dark text-[14px]'
                                                    : 'bg-white not-italic font-light text-sm leading-6 h-[40px] relative w-full border border-gray-dark shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-transparent focus:border-gray-dark text-[14px] text-gray-darker'
                                            )}
                                            data-testid={`select-${name}`}
                                        >
                                <span className="block truncate">
                                    {selected?.name ?? t(placeholder || '')}
                                </span>
                                            <span
                                                className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={!disabled && open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options
                                                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                {[{id: null, name: '-'}, ...items].map(
                                                    (element) => (
                                                        <Listbox.Option
                                                            key={element.id}
                                                            data-testid={`select-${name}`}
                                                            className={({active}) =>
                                                                classNames(
                                                                    active
                                                                        ? 'text-gray-dark bg-gray-light'
                                                                        : 'text-gray-darker',
                                                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={element}
                                                        >
                                                            {({selected, active}) => (
                                                                <>
                                                        <span
                                                            className={classNames(
                                                                selected
                                                                    ? 'font-semibold'
                                                                    : 'font-normal',
                                                                'block truncate'
                                                            )}
                                                        >
                                                            {element.name}
                                                        </span>

                                                                    {selected ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-gray-dark bg-gray-light'
                                                                                    : 'text-gray-dark',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                <CheckIcon
                                                                    className="h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                )}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                    {hasAdd && !disabled
                        ? <div className={"ml-2 mt-8 items-center justify-center"}>
                            <Button
                                onClick={handleAddNew}
                                icon={
                                    <PlusIcon
                                        className={'h-5 w-5 text-black mx-2'}
                                    />
                                }
                                variant={"gray-light"}
                            />
                        </div>
                        : null}
                </div>
                : null}
        </div>
    );
};
