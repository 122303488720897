import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { classNames } from '../../utils';
import { useHttpClient } from '../../shared/http-client';
import { PageBody } from '../../elements/page-body';
import { PageSubHeader } from '../../elements/page-subheader';

export const ProyectosDetail = () => {
    const params = useParams();
    const route = useLocation();

    let tabs: any[] = [
        {
            key: 'planos',
            label: 'proyectos.tabs.imagenes'
        },
        {
            key: 'archivos',
            label: 'proyectos.tabs.archivos'
        },
        {
            key: 'base',
            label: 'proyectos.tabs.base'
        }
    ];

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [item, setItem] = useState<any>({});
    const [data, setData] = useState<any>({});
    const [count, setCount] = useState<any>(0);
    const [isRevisado, setIsRevisado] = useState(false);
    const [tabsList, setTabsList] = useState<any[]>(tabs);

    useEffect(() => {
        api(`/proyectos/${params.id}/`, 'GET');
    }, []);

    useEffect(() => {
        const tabsTemp: any[] = isRevisado
            ? tabs
            : [
                  {
                      key: 'planos',
                      label: 'proyectos.tabs.imagenes'
                  }
              ];
        setTabsList(tabsTemp);
    }, [isRevisado]);

    useEffect(() => {
        if (!state.isLoading) {
            if (state.data) {
                if (state.path.includes('proyectos')) {
                    const isRevisado = state.data.is_procesado;
                    setIsRevisado(isRevisado);
                }
            }

            if (state.error) {
                if (state.path.includes('send_pdf_intervencion')) {
                    toast.error(t('intervention-detail.error.save-pdf') as any);
                } else {
                    toast.error(state.error.detail);
                }
            }
        }
    }, [state]);

    return (
        <PageBody title={'proyecto.title.tabs'}>
            <div>
                <PageSubHeader
                    path={`/proyectos/${params.id}/`}
                    headers={[
                        { key: 'id' },
                        { key: 'name' },
                        { key: 'expediente' },
                        { key: 'im3_2' }
                    ]}
                    doBackAction={() => {
                        navigate('/proyectos');
                    }}
                ></PageSubHeader>
                <div
                    className={
                        'flex justify-between w-full border-b my-4 py-[18px] border-gray h-[16px] bg-white rounded-t-md'
                    }
                >
                    <div className="flex items-center space-x-[8px] rounded-md">
                        {tabsList.map(
                            (
                                tab: { key: string; label: string },
                                idx: number
                            ) => (
                                <span
                                    key={idx}
                                    onClick={() => {
                                        navigate(tab.key);
                                    }}
                                    className={classNames(
                                        'min-w-[80px] font-light text-sm leading-6 cursor-pointer rounded-t-md px-4 py-1 hover:text-white hover:bg-primary',
                                        route.pathname.includes(tab.key)
                                            ? 'text-white border-b-4 border-b-primary font-semibold bg-secondary '
                                            : 'text-gray-800 border-b-4 border-b-transparent bg-gray-100'
                                    )}
                                >
                                    <div
                                        className={
                                            'flex flex-row items-center justify-between'
                                        }
                                    >
                                        {t(tab.label)}
                                        {tab.key === 'abiertos' ? (
                                            <div
                                                className={
                                                    'ml-1 text-[9px] font-bold px-2 rounded-full bg-gray-400 text-gray-600'
                                                }
                                            >
                                                {count}
                                            </div>
                                        ) : null}
                                    </div>
                                </span>
                            )
                        )}
                    </div>
                </div>
                <Outlet />
            </div>
        </PageBody>
    );
};

